class Config {
  componentName: string = "vue-page-stack";
  keyName: string = "stack-key";
  pushName: string = "push";
  goName: string = "go";
  replaceName: string = "replace";
  backName: string = "back";
  forwardName: string = "forward";
}

export default new Config();

































































import mixins from "vue-typed-mixins";
import MixinsPageForm from "@/mixins/page-form";

export default mixins(MixinsPageForm).extend({
  name: "DiseaseInfo",
  components: {},
  data: () => ({
    crop: {
      tomato: {
        diseaseList: [
          {
            name: "궤양병",
            imgPath: "/img/disease/tomato/토마토_흰가루병.png",
            path:
              "https://www.nongsaro.go.kr/portal/ps/pss/pssa/sicknsSearchDtl.ps?menuId=PS00202&sKidofcomdtyTabCode=VC&sKidofcomdtyCode=VC010803&hlsctCode=&sicknsCode=D00004102&nnmyInsectCode=",
          },
          {
            name: "잿빛곰팡이병",
            imgPath: "/img/disease/tomato/토마토_잿빛곰팡이병.png",
            path:
              "https://www.nongsaro.go.kr/portal/ps/pss/pssa/sicknsSearchDtl.ps?menuId=PS00202&sKidofcomdtyTabCode=VC&sKidofcomdtyCode=VC010803&hlsctCode=&sicknsCode=D00001545&nnmyInsectCode=",
          },
          {
            name: "흰가루병",
            imgPath: "/img/disease/tomato/토마토_흰가루병.png",
            path:
              "https://www.nongsaro.go.kr/portal/ps/pss/pssa/sicknsSearchDtl.ps?menuId=PS00202&sKidofcomdtyTabCode=VC&sKidofcomdtyCode=VC010803&hlsctCode=&sicknsCode=D00001534&nnmyInsectCode=",
          },
          {
            name: "잎곰팡이병",
            imgPath: "/img/disease/tomato/토마토_잎곰팡이병.png",
            path:
              "https://www.nongsaro.go.kr/portal/ps/pss/pssa/sicknsSearchDtl.ps?menuId=PS00202&sKidofcomdtyTabCode=VC&sKidofcomdtyCode=VC010803&hlsctCode=&sicknsCode=D00001533&nnmyInsectCode=",
          },
          {
            name: "황화잎말림바이러스",
            imgPath: "/img/disease/tomato/토마토_황화잎말림바이러스.png",
            path:
              "https://www.nongsaro.go.kr/portal/ps/pss/pssa/sicknsSearchDtl.ps?menuId=PS00202&sKidofcomdtyTabCode=VC&sKidofcomdtyCode=VC010803&hlsctCode=&sicknsCode=D00004252&nnmyInsectCode=",
          },
        ],
      },
      strawberry: {
        diseaseList: [
          {
            name: "잿빛곰팡이병",
            imgPath: "/img/disease/strawberry/딸기_잿빛곰팡이병.png",
            path:
              "https://www.nongsaro.go.kr/portal/ps/pss/pssa/sicknsSearchDtl.ps?menuId=PS00202&sKidofcomdtyTabCode=VC&sKidofcomdtyCode=VC010804&hlsctCode=&sicknsCode=D00000440&nnmyInsectCode=",
          },
          {
            name: "흰가루병",
            imgPath: "/img/disease/strawberry/딸기_흰가루병.png",
            path:
              "https://www.nongsaro.go.kr/portal/ps/pss/pssa/sicknsSearchDtl.ps?menuId=PS00202&sKidofcomdtyTabCode=VC&sKidofcomdtyCode=VC010804&hlsctCode=&sicknsCode=D00000459&nnmyInsectCode=",
          },
          {
            name: "탄저병",
            imgPath: "/img/disease/strawberry/딸기_탄저병.png",
            path:
              "https://www.nongsaro.go.kr/portal/ps/pss/pssa/sicknsSearchDtl.ps?menuId=PS00202&sKidofcomdtyTabCode=VC&sKidofcomdtyCode=VC010804&hlsctCode=&sicknsCode=D00000448&nnmyInsectCode=",
          },
          {
            name: "세균모무늬병",
            imgPath: "/img/disease/strawberry/딸기_세균모무늬병.png",
            path:
              "https://www.nongsaro.go.kr/portal/ps/pss/pssa/sicknsSearchDtl.ps?menuId=PS00202&sKidofcomdtyTabCode=VC&sKidofcomdtyCode=VC010804&hlsctCode=&sicknsCode=D00004061&nnmyInsectCode=",
          },
          {
            name: "뱀눈무늬병",
            imgPath: "/img/disease/strawberry/딸기_뱀눈무늬병.png",
            path:
              "https://www.nongsaro.go.kr/portal/ps/pss/pssa/sicknsSearchDtl.ps?menuId=PS00202&sKidofcomdtyTabCode=VC&sKidofcomdtyCode=VC010804&hlsctCode=&sicknsCode=D00000449&nnmyInsectCode=",
          },
          // {
          //   name: "꽃곰팡이병",
          //   imgPath: "/img/disease/strawberry/딸기_꽃곰팡이병.png",
          //   path: "",
          // },
        ],
      },
    },
  }),
  mounted() {
    this.$nextTick(async () => {});
  },
  methods: {
    movePage(item) {
      console.log("item : ", item);

      window.open(item.path, "_blank");
    },
  },
});

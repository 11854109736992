import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import { RootState, TopToolbarState } from "@/types";

const state: TopToolbarState = {
  title: "Toolbar title",
  clazz: "",
  dark: true,
  elevation: 0,
  color: "primary",
  changedStyle: false,
};

export interface Style {
  dark: boolean;
  clazz: string;
  elevation: number;
  color: string;
}

export const getters: GetterTree<TopToolbarState, RootState> = {
  title: (theState: TopToolbarState) => {
    return theState.title;
  },
  clazz: (theState: TopToolbarState) => {
    return theState.clazz;
  },
  dark: (theState: TopToolbarState) => {
    return theState.dark;
  },
  elevation: (theState: TopToolbarState) => {
    return theState.elevation;
  },
  color: (theState: TopToolbarState) => {
    return theState.color;
  },
  changedStyle: (theState: TopToolbarState) => {
    return theState.changedStyle;
  },
};

const mutations: MutationTree<TopToolbarState> = {
  setTitle(theState: TopToolbarState, title: string) {
    theState.title = title;
  },
  setStyle(theState: TopToolbarState, style: Style) {
    if (style.dark != null) {
      theState.dark = style.dark;
      theState.changedStyle = true;
    }
    if (style.clazz != null) {
      theState.clazz = style.clazz;
      theState.changedStyle = true;
    }
    if (style.elevation != null) {
      theState.elevation = style.elevation;
      theState.changedStyle = true;
    }
    if (style.color != null) {
      theState.color = style.color;
      theState.changedStyle = true;
    }
  },
};

export const actions: ActionTree<TopToolbarState, RootState> = {
  changeTitle({ commit }, title: string) {
    commit("setTitle", title);
  },
  changeStyle({ commit }, style: Style) {
    commit("setStyle", style);
  },
  changeOrgStyle({ commit }) {
    commit("setStyle", {
      dark: true,
      elevation: 5,
      clazz: "app-bar-primary-bg",
      color: "",
    });
    state.changedStyle = false;
  },
};

export const topToolbar: Module<TopToolbarState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

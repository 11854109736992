import { RouterBeforeTask } from "@/plugins/vue-page-stack/router-before-task";
import { UserModel } from "@/models/user/user.model";

/**
 * interface
 */
// Store root state
export interface RootState {
  topToolbar: TopToolbarState;
  app: AppState;
  auth: AuthState;
  storage: StorageState;
}

// Store modules state
export interface TopToolbarState {
  title: string;
  dark: boolean;
  clazz: string;
  elevation: number;
  color: string;
  changedStyle: boolean;
}

export interface Filter {
  type: string;
  filter: any;
}

export interface SessionItem {
  type: string;
  items: any;
}

export interface StorageItem {
  type: string;
  items: any;
}

export interface StorageState {
  filters: Map<string, any>;
  sessionItems: Map<string, any>;
  storageItems: Map<string, any>;
}

export interface AppState {
  name: string;
  size: WindowSize;
  routerBeforeTask: RouterBeforeTask;
  modal: {
    alert: AlertModal;
  };
  printClass: null | string;
  isApp: boolean;
  isMobile: boolean;
  analysis: {
    ready: boolean;
    index: number;
    items: any;
  };
}

export interface AuthState {
  user: UserModel | null;
  sessionCheckTime: Date | null;
  mobileToken: string | null;
  systemProperties: MobileSystemProperties | null;
  changedCompanyDate: Date;
}

export interface MobileSystemProperties {
  timezone: string;
  locale: string;
  manufacturer: string;
  model: string;
  product: string;
  hardware: string;
  os: string;
  versionSdk: number;
  versionRelease: string;
  deviceToken: string;
  packageName: string;
  versionCode: number;
  versionName: string;
}

export interface WindowSize {
  width: number;
  height: number;
}

export interface AlertModal {
  visible: boolean;
  title: string;
  body: string;
  showCancelButton: boolean;
  cancelButtonText: string;
  confirmButtonText: string;
  promise: {
    resolve: any;
    reject: any;
  };
  allowBackCloseEvent: boolean;
}

export interface TableRequest {
  draw: number;
  start: number;
  length: number;
  orderColumnName: string;
  order: TableOrder;
  searchColumns?: any;
}

export interface TableResponse {
  recordsFiltered: number;
  recordsTotal: number;
  draw: number;
  data: any[];
}

export interface DiseaseAnalysis {
  index: number;
  cropCode: number;
  status: string; // LOADING, DONE, ERROR
  item: any;
  message: string | null;
  date: string;
}

/**
 * enum
 */

export enum UpdateEventType {
  RELOAD = "reload",
  FILTER = "filter",
  DELETE = "delete",
  UPDATE = "update",
  CONFIRM = "confirm",
  CANCEL = "cancel",
  PAGE = "page",
  TABLE = "table",
  EVENT = "event",
  DEACTIVATE = "deactivate",
}

export enum TableOrder {
  ASC = "asc",
  DESC = "desc",
}

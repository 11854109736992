








































































































import mixins from "vue-typed-mixins";
import MixinsPageForm from "@/mixins/page-form";
import DiseaseService from "@/services/disease/disease.service";
import { TableResponse } from "@/types";
import MixinsScroll from "@/mixins/single/scroll";

export default mixins(MixinsPageForm, MixinsScroll).extend({
  name: "DiseaseManagerAnnotation",
  components: {},
  data: () => ({
    drawing: {
      isDrawing: false,
      isStarted: false,
      imageWidth: 0,
      imageHeight: 0,
      startX: 0,
      startY: 0,
      width: 0,
      height: 0,
      rect: null as any,
      imageLoading: true,
    },
    diseaseAnalysis: null as any,
    fab: false,
    resultStatus: null as any,
    resultStatusList: [
      { label: "주석", value: "ANNOTATION" },
      { label: "병충해 없음", value: "NONE" },
    ],
  }),
  mounted() {
    this.$nextTick(async () => {
      const id = Number(this.$route.query.id);
      if (isNaN(id)) {
        await this.notFound();
        return;
      }

      try {
        const tableResponse = (await DiseaseService.getTable({
          order: "desc",
          orderColumnName: "id",
          start: 0,
          length: 1,
          searchColumns: {
            id: id,
          },
        })) as TableResponse;
        if (tableResponse.data.length > 0) {
          this.diseaseAnalysis = tableResponse.data[0];
          if (this.diseaseAnalysis.annotations == null) {
            this.diseaseAnalysis.annotations = [];
          }
          //console.log("diseaseAnalysis : ", this.diseaseAnalysis);
          setTimeout(() => {
            this.changedImage();
          }, 500);
        } else {
          await this.notFound();
        }
      } catch (e) {
        console.log(e);
        await this.notFound();
      }
    });
  },
  watch: {},
  methods: {
    changedImage() {
      const diseaseAnalysis = this.diseaseAnalysis;
      const canvas = this.$refs.canvas as any;
      //console.log("canvas : ", canvas);
      const drawingCanvas = this.$refs.drawingCanvas as any;
      //console.log("drawingCanvas : ", drawingCanvas);
      const resultStatus = diseaseAnalysis.resultStatus;
      if (resultStatus === "ANNOTATION" || resultStatus === "NONE") {
        this.resultStatus = resultStatus;
      } else {
        this.resultStatus = "ANNOTATION";
      }
      // const drawingCanvas = this.$refs.drawingCanvas;
      const ctx = canvas.getContext("2d") as any;
      // this.drawing.offsetX = $el.offsetLeft
      // this.drawing.offsetY = $el.offsetTop;

      const image = new Image();

      const drawing = this.drawing;
      drawing.imageLoading = true;
      const drawAnnotation = this.drawAnnotation;
      image.onload = function (e) {
        ctx.canvas.width = image.width;
        ctx.canvas.height = image.height;
        drawingCanvas.width = image.width;
        drawingCanvas.height = image.height;
        if (image.width < image.height) {
          diseaseAnalysis.clazz = "vertical";
        } else {
          diseaseAnalysis.clazz = "";
        }
        drawing.imageWidth = image.width;
        drawing.imageHeight = image.height;
        //console.log(`width : ${image.width}, height : ${image.height}`);
        // drawingCanvas.width = image.width;
        // drawingCanvas.height = image.height;
        // $el.width = image.width;
        // $el.height = image.height;
        ctx.drawImage(image, 0, 0);
        if (diseaseAnalysis.annotations != null) {
          diseaseAnalysis.annotations.forEach((label) => {
            // label.id, label.minX, label.maxX, label.minY, label.maxY
            drawAnnotation(label);
          });
        }
        //console.log("drawing : ", drawing);
        drawing.imageLoading = false;
      };
      image.style.display = "inline";
      image.src = diseaseAnalysis.imageUrl;
    },
    changeDrawing(type) {
      const drawing = this.drawing;
      let isDrawing = false;
      if (type === "add") {
        isDrawing = true;
      } else if (type === "confirm") {
        // 추가
        //console.log("confirm");
        this.addAnnotation();
      } else {
        if (drawing.isDrawing) {
          this.changedImage();
        }
        //console.log("unknown type : ", type);
      }
      drawing.isDrawing = isDrawing;
      if (!isDrawing) {
        drawing.startX = 0;
        drawing.startY = 0;
        drawing.width = 0;
        drawing.height = 0;
      }
    },
    addAnnotation() {
      const drawing = this.drawing;

      let id = 1;
      const annotations = this.diseaseAnalysis.annotations;
      annotations.forEach((annotation) => {
        id = annotation.id + 1;
      });

      const annotation = {
        id: id,
        name: "",
        minX: drawing.startX,
        minY: drawing.startY,
        maxX: drawing.startX + drawing.width,
        maxY: drawing.startY + drawing.height,
      };

      if (annotation.minX > annotation.maxX) {
        const temp = annotation.minX;
        annotation.minX = annotation.maxX;
        annotation.maxX = temp;
      }

      if (annotation.minY > annotation.maxY) {
        const temp = annotation.minY;
        annotation.minY = annotation.maxY;
        annotation.maxY = temp;
      }

      annotations.push(annotation);

      this.drawAnnotation(annotation);
    },
    drawAnnotation(item) {
      const lineWidth = this.getLineWidth();
      const fontSize = this.getFontSize();

      const canvas = this.$refs.canvas as any;
      const ctx = canvas.getContext("2d");
      ctx.strokeStyle = "black";
      ctx.fillStyle = "black";
      ctx.rect(item.minX, item.minY, item.maxX - item.minX, item.maxY - item.minY);
      ctx.lineWidth = lineWidth;
      ctx.stroke();
      ctx.font = fontSize + "px Arial";

      ctx.fillText("ID " + item.id, item.minX - (lineWidth + 1), item.minY - (lineWidth + 5));
    },
    removeAnnotation(item) {
      let index = null;
      const annotations = this.diseaseAnalysis.annotations;
      annotations.some((annotation, _index) => {
        if (annotation.id === item.id) {
          index = _index;
          return true;
        }
      });
      if (index != null) {
        annotations.splice(index, 1);
        annotations.forEach((annotation, _index) => {
          annotation.id = _index + 1;
        });
        console.log("changed annotations");
        this.changedImage();
      } else {
        console.log("not found annotation index : ", index);
      }
      //console.log("annotation length : ", analysis.annotations.length);
    },
    handleTouchStart(e) {
      // e.preventDefault();
      // e.stopPropagation();
      const drawing = this.drawing;
      if (!drawing.isDrawing) {
        return;
      }
      const rect = e.target.getBoundingClientRect();
      this.drawing.rect = rect;
      const x = e.targetTouches[0].pageX - rect.x;
      const y = e.targetTouches[0].pageY - (rect.y + this.scroll.scrollTop);
      this.handleStart(x, y);
    },

    handleTouchMove(e) {
      const drawing = this.drawing;
      // if we're not dragging, just return
      if (!drawing.isStarted) {
        return;
      }
      if (drawing.rect != null) {
        const rect = drawing.rect;
        const x = e.targetTouches[0].pageX - rect.x;
        const y = e.targetTouches[0].pageY - (rect.y + this.scroll.scrollTop);
        this.handleMove(x, y);
      }
    },
    handleTouchEnd(e) {
      // e.preventDefault();
      // e.stopPropagation();
      this.handleEnd();
    },

    handleMouseDown(e) {
      e.preventDefault();
      e.stopPropagation();
      this.handleStart(e.offsetX, e.offsetY);
    },
    handleMouseUp(e) {
      e.preventDefault();
      e.stopPropagation();
      this.handleEnd();
    },
    handleMouseMove(e) {
      this.handleMove(e.offsetX, e.offsetY);
    },

    handleStart(x, y) {
      const drawing = this.drawing;
      if (!drawing.isDrawing) {
        return;
      }
      // save the starting x/y of the rectangle
      const canvas = this.$refs.drawingCanvas as any;
      drawing.startX = parseInt(String((x / canvas.clientWidth) * drawing.imageWidth));
      drawing.startY = parseInt(String((y / canvas.clientHeight) * drawing.imageHeight));

      // set a flag indicating the drag has begun
      drawing.isStarted = true;
    },
    handleMove(x, y) {
      const drawing = this.drawing;
      // if we're not dragging, just return
      if (!drawing.isStarted) {
        return;
      }

      // Put your mousemove stuff here
      const canvas = this.$refs.drawingCanvas as any;
      const ctx = canvas.getContext("2d");
      const lineWidth = this.getLineWidth();
      ctx.lineWidth = lineWidth;

      // get the current mouse position
      const mouseX = parseInt(String((x / canvas.clientWidth) * drawing.imageWidth));
      const mouseY = parseInt(String((y / canvas.clientHeight) * drawing.imageHeight));

      // clear the canvas
      ctx.clearRect(0, 0, drawing.imageWidth, drawing.imageHeight);

      // calculate the rectangle width/height based
      // on starting vs current mouse position
      drawing.width = mouseX - drawing.startX;
      drawing.height = mouseY - drawing.startY;

      // draw a new rect from the start position
      // to the current mouse position
      ctx.strokeRect(drawing.startX, drawing.startY, drawing.width, drawing.height);
    },
    handleEnd() {
      const drawing = this.drawing;

      // the drag is over, clear the dragging flag
      drawing.isStarted = false;
    },
    getLineWidth() {
      const drawing = this.drawing;
      let maxSize = drawing.imageWidth;
      if (maxSize < drawing.imageHeight) {
        maxSize = drawing.imageHeight;
      }
      return parseInt(String(maxSize / 200));
    },
    getFontSize() {
      const drawing = this.drawing;
      let maxSize = drawing.imageWidth;
      if (maxSize < drawing.imageHeight) {
        maxSize = drawing.imageHeight;
      }
      return parseInt(String(maxSize / 20));
    },
    async submit() {
      console.log("submit");
      try {
        const diseaseAnalysis = this.diseaseAnalysis;

        const params = {
          resultStatus: this.resultStatus,
          annotations: [],
        };
        if (params.resultStatus === "ANNOTATION") {
          params.annotations = diseaseAnalysis.annotations;
        }
        const resultAnalysis = (await DiseaseService.update(diseaseAnalysis.id, params)) as any;
        console.log("resultAnalysis : ", resultAnalysis);
        // await core.alert.show({
        //   title: "알림",
        //   body: "저장되었습니다",
        // });
        this.goBack();
      } catch (e) {
        console.log(e);
      }
    }
  },
});

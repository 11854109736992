























































import core from "@/core";
import mixins from "vue-typed-mixins";
import MixinsPageForm from "@/mixins/page-form";
import { UserModel } from "@/models/user/user.model";
import UserService from "@/services/user/user.service";
import { cloneDeep } from "lodash";
import { SubMenu } from "@/router";

export default mixins(MixinsPageForm).extend({
  name: "UserMyInfoView",
  data: () => ({
    type: "view",
    view: {
      username: "",
      phone: "",
    },
    form: {
      email: "" as string | null,
      name: "",
      phone: "",
      certificationNumber: "",
      phoneVerifyToken: "",
      profileFileDetailId: null as any,
    },
    auth: {
      visible: {
        btn: false,
        help: false,
      },
      processing: false,
    },
    image: {
      item: null,
      src: null,
    } as any,
    user: null as UserModel | null,
    processing: false,
    iconSubMenu: { edit: null as SubMenu | null },
  }),
  mounted() {
    // 페이지 로딩 후 호출
    this.$nextTick(async () => {
      const user: UserModel = (this.user = await this.$store.getters["auth/user"]());
      //console.log("user : ", user);
      this.view.username = user.username;
      this.view.phone = core.utils.format.hyphenPhone(user.phone);

      if (user.email != null) {
        this.form.email = user.email;
      } else {
        this.form.email = "정보없음";
      }

      this.form.name = user.name;
      this.form.phone = user.phone;

      // TODO :
      // if (user.profile != null) {
      //   this.image.src = user.profile.uri;
      //   this.form.profileFileDetailId = user.profile.id;
      // }
      // this.type = "edit";
    });
  },
  watch: {
    "iconSubMenu.edit.event"(event) {
      const item = this.iconSubMenu.edit;
      item.event = false;
      if (event) {
        this.$router.push("/user/me/info/edit");
      }
    },
    "form.phone"() {
      this.form.phone = core.utils.format.hyphenPhone(this.form.phone);
      this.auth.visible.help = false;
      this.form.phoneVerifyToken = "";
      this.form.certificationNumber = "";
      this.auth.visible.btn = this.form.phone != this.view.phone;
    },
    type(type) {
      if (type === "view") {
        const user = this.user as UserModel;
        this.view.username = user.username;
        this.view.phone = core.utils.format.hyphenPhone(user.phone);

        this.form.email = user.email;
        this.form.name = user.name;
        this.form.phone = user.phone;
        this.form.certificationNumber = "";
        this.form.phoneVerifyToken = "";

        // TODO :
        // if (user.profile != null) {
        //   this.image.src = user.profile.uri;
        //   this.form.profileFileDetailId = user.profile.id;
        // }
      }
    },
  },
  methods: {
    onChangeImageFile(e) {
      this.inputImageFile(e.target.files);
    },
    inputImageFile(files) {
      if (files.length) {
        let file = files[0];
        if (!/^image\//.test(file.type)) {
          core.alert.show({
            title: "알림",
            body: "이미지 파일만 등록이 가능합니다",
          });
          return false;
        }
        this.image.name = file.name;
        this.preview(file);
      }
    },

    clearImage() {
      this.image.src = "/img/user/profile.png";
      this.image.item = null;
      this.form.profileFileDetailId = null;
    },

    async preview(file) {
      const image = this.image;
      core.loader.show("이미지 크기 변환중...");
      const item = (image.item = await core.utils.image.getImageBlob(file, 500)) as any;
      image.src = item.dataUri;
      core.loader.hide();
    },

    async sendPhoneVerify() {
      //console.log("call send phone verify");
      await this.validate();
      const observer = this.$refs.observer as any;
      if (observer.errors != null && observer.errors.phone && observer.errors.phone.length > 0) {
        return;
      }
      this.auth.processing = true;

      try {
        const token = (await UserService.createPhoneVerify(this.form.phone)) as string;
        this.form.phoneVerifyToken = token;
        //console.log("token : ", token);
        this.auth.visible.help = true;
      } catch (e) {
        console.log(e);
        this.errorSubmit(e);
      } finally {
        this.auth.processing = false;
      }
    },
    async checkPhoneVerify() {
      const token = this.form.phoneVerifyToken;
      const certificationNumber = this.form.certificationNumber;
      const phone = this.form.phone;
      try {
        const result = (await UserService.checkPhoneVerify(
          token,
          phone,
          certificationNumber
        )) as string;
        //console.log("result : ", result);
        return true;
      } catch (e) {
        //console.log("error : ", e);
        this.errorSubmit(e);
        return false;
      }
    },
    async submit() {
      if (this.type === "view") {
        this.type = "edit";
        // await core.alert.show({
        //   title: "알림",
        //   body: "정보를 수정하실 수 있습니다.",
        // });
        return;
      }

      if (this.auth.visible.btn && this.isBlank(this.form.phoneVerifyToken)) {
        await this.sendPhoneVerify();
        return;
      }

      if (await this.validate()) {
        core.loader.show();
        this.processing = true;
        try {
          const params = cloneDeep(this.form) as any;
          if (this.image.item != null) {
            const item = this.image.item;
            //console.log("item : ", item);
            // image 저장
            const formData = new FormData();
            formData.append("imageFile", item.blob, item.filename);
            const fileDetailId = await UserService.createProfileImage(formData);
            params.profileFileDetailId = fileDetailId;
          }
          await UserService.update(params);
          const user: UserModel = await this.$store.getters["auth/user"](true);
          core.loader.hide();

          await core.alert.show({
            title: "알림",
            body: "회원정보가 수정되었습니다.",
          });
          await this.$router.back();
        } catch (e) {
          core.loader.hide();
          this.errorSubmit(e);
        }
        this.processing = false;
      }
    },
  },
});

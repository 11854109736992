import core from "@/core";

abstract class AbstractCoreService {
  protected readonly url: string;
  protected readonly itemKey: string;
  protected readonly replaceParams?: [string];

  protected constructor(url: string, itemKey: string, replaceParams?: [string]) {
    this.url = url;
    this.itemKey = itemKey;
    this.replaceParams = replaceParams;
  }

  getUrl(replaceParams?: [string]) {
    let url = this.url;
    if (replaceParams != null && this.replaceParams != null) {
      const length = this.replaceParams.length;
      for (let i = 0; i < length; i++) {
        const param = this.replaceParams[i];
        const replace = replaceParams[i];
        url = url.replace(param, replace);
      }
    }
    return url;
  }

  get(id: number, params?: any, replaceParams?: [string]) {
    const url = this.getUrl(replaceParams);
    return new Promise((resolve, reject) => {
      core.http
        .getJson(`${url}/${id}`, null, params)
        .then((data) => {
          if (typeof data === "object") {
            const item = data[this.itemKey];
            if (item != null) {
              resolve(item);
            } else {
              reject({
                message: "not found item",
              });
            }
          } else {
            reject({
              message: "data type error",
            });
          }
        })
        .catch((reason) => {
          reject(reason);
        });
    });
  }

  getList(replaceParams?: [string]) {
    const url = this.getUrl(replaceParams);
    return new Promise((resolve, reject) => {
      core.http
        .getJson(`${url}`)
        .then((data) => {
          if (typeof data === "object") {
            const item = data[this.itemKey + "List"];
            if (item != null) {
              resolve(item);
            } else {
              reject({
                message: "not found item",
              });
            }
          } else {
            reject({
              message: "data type error",
            });
          }
        })
        .catch((reason) => {
          reject(reason);
        });
    });
  }

  create(params: any, replaceParams?: [string]) {
    const url = this.getUrl(replaceParams);
    const strParams = JSON.stringify(params);
    return new Promise((resolve, reject) => {
      core.http
        .postJson(`${url}`, strParams)
        .then((data) => {
          if (typeof data === "object") {
            const item = data[this.itemKey];
            if (item != null) {
              resolve(item);
            } else {
              reject({
                message: "not found item",
              });
            }
          } else {
            reject({
              message: "data type error",
            });
          }
        })
        .catch((reason) => {
          //console.log('error: ', reason);
          reject(reason);
        });
    });
  }

  update(id: number, params: any, replaceParams?: [string]) {
    const url = this.getUrl(replaceParams);
    return new Promise((resolve, reject) => {
      const strJson = JSON.stringify(params);
      core.http
        .putJson(`${url}/${id}`, strJson)
        .then((data) => {
          if (typeof data === "object") {
            const item = data[this.itemKey];
            if (item != null) {
              resolve(item);
            } else {
              reject({
                message: "not found item",
              });
            }
          } else {
            reject({
              message: "data type error",
            });
          }
        })
        .catch((reason) => {
          reject(reason);
        });
    });
  }

  delete(id: number, params?: any, replaceParams?: [string]) {
    const url = this.getUrl(replaceParams);
    return new Promise((resolve, reject) => {
      const fullUrl = `${url}/${id}${params == null ? "" : "?" + core.http.objToUrlParams(params)}`;
      core.http
        .delete(`${fullUrl}`, null)
        .then((data) => {
          resolve(data);
        })
        .catch((reason) => {
          reject(reason);
        });
    });
  }
}

export default AbstractCoreService;

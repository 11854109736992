






























import mixins from "vue-typed-mixins";
import core from "@/core";
import store from "@/store";

export default mixins().extend({
  name: "AppBottomBar",
  data: () => ({
    bottomNav: "",
    companyId: 0,
    menuList: [],
    app: store.state.app,
    auth: store.state.auth,
    menuItems: {
      selected: null as any,
      list: [] as any,
    },
  }),
  computed: {
    title() {
      return this.$store.getters["topToolbar/title"];
    },
    dark() {
      return this.$store.getters["topToolbar/dark"];
    },
    clazz() {
      return this.$store.getters["topToolbar/clazz"];
    },
    elevation() {
      return this.$store.getters["topToolbar/elevation"];
    },
    color() {
      return this.$store.getters["topToolbar/color"];
    },
  },
  created() {
    //this.changedCompany();
  },
  mounted() {
    this.$nextTick(() => {
      this.bottomNav = "home";
    });
  },
  watch: {
    bottomNav(bottomNav) {
      this.$emit("update:bottomNav", bottomNav);
    },
  },
  methods: {
    movePage(item) {
      console.log("move page : ", item);
      this.$router.push(item.path);
    },
    goBack() {
      this.$router.back();
    },
    async logout() {
      const result = await core.alert.show({
        title: "확인",
        body: "현재 인증된 정보가 해제됩니다.<br>계속 진행하시겠습니까?",
        showCancelButton: true,
      });
      if (result === "confirm") {
        await this.$router.push("/logout");
      }
    },
  },
});

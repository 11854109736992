





























































































































import core from "@/core";
import mixins from "vue-typed-mixins";
import MixinsPageForm from "@/mixins/page-form";
import { UserModel } from "@/models/user/user.model";
import UserService from "@/services/user/user.service";
import { cloneDeep } from "lodash";
import { SubMenu } from "@/router";
import UpdateEvent from "@/models";
import { UpdateEventType } from "@/types";

export default mixins(MixinsPageForm).extend({
  name: "UserMyInfo",
  data: () => ({
    view: {
      username: "",
      phone: "",
    },
    form: {
      email: "" as string | null,
      name: "",
      phone: "",
    },
    accept: "image/*",
    image: {
      item: null,
      src: null,
    } as any,
    user: null as UserModel | null,
    processing: false,
    iconSubMenu: { edit: null as SubMenu | null },
    modal: {
      selectCamera: {
        visible: false,
        updateEvent: null as UpdateEvent | null,
      },
    },

    formConvertField: {
      username: "user_username",
      email: "user_email",
      name: "user_name",
      phone: "user_phone",
      confirmPassword: "user_confirmPassword",
      certificationNumber: "user_certificationNumber",
    },
  }),
  mounted() {
    // 페이지 로딩 후 호출
    this.$nextTick(async () => {
      const user: UserModel = (this.user = await this.$store.getters["auth/user"]());
      //console.log("user : ", user);
      this.view.username = user.username;
      this.view.phone = core.utils.format.hyphenPhone(user.phone);

      this.form.email = user.email;
      this.form.name = user.name;
      this.form.phone = user.phone;

      // TODO :
      // if (user.profile != null) {
      //   this.image.src = user.profile.uri;
      //   this.form.profileFileDetailId = user.profile.id;
      // }
    });
  },
  watch: {
    "form.phone"() {
      this.form.phone = core.utils.format.hyphenPhone(this.form.phone);
    },
    type(type) {
      if (type === "view") {
        const user = this.user as UserModel;
        this.view.username = user.username;
        this.view.phone = core.utils.format.hyphenPhone(user.phone);

        this.form.email = user.email;
        this.form.name = user.name;
        this.form.phone = user.phone;
      }
    },
    "modal.selectCamera.updateEvent"(event) {
      if (event != null) {
        if (event.result === UpdateEventType.CONFIRM) {
          const item = event.item;
          if (item === "camera") {
            (this.$refs.profileCameraFile as any).click();
          } else if (item === "image") {
            (this.$refs.profileImageFile as any).click();
          } else {
            console.log("unknown item : ", item);
          }
        }
      }
    },
  },
  methods: {
    showCameraModal() {
      const modal = this.modal.selectCamera;
      modal.visible = true;
    },
    onChangeImageFile(e) {
      this.inputImageFile(e.target.files);
    },
    inputImageFile(files) {
      if (files.length) {
        let file = files[0];
        if (!/^image\//.test(file.type)) {
          core.alert.show({
            title: "알림",
            body: "이미지 파일만 등록이 가능합니다",
          });
          return false;
        }
        this.image.name = file.name;
        this.preview(file);
      }
    },

    clearImage() {
      this.image.src = "/img/user/profile.png";
      this.image.item = null;
    },

    async preview(file) {
      const image = this.image;
      core.loader.show("이미지 크기 변환중...");
      const item = (image.item = await core.utils.image.getImageBlob(file, 500)) as any;
      image.src = item.dataUri;
      core.loader.hide();
    },
    async submit() {
      if (await this.validate()) {
        core.loader.show();
        this.processing = true;
        try {
          const params = cloneDeep(this.form) as any;
          // if (this.image.item != null) {
          //   const item = this.image.item;
          //   //console.log("item : ", item);
          //   // image 저장
          //   const formData = new FormData();
          //   formData.append("imageFile", item.blob, item.filename);
          //   const fileDetailId = await UserService.createProfileImage(formData);
          //   params.profileFileDetailId = fileDetailId;
          // }

          if (this.isBlank(params.email)) {
            delete params.email;
          }

          await UserService.update(params);
          const user: UserModel = await this.$store.getters["auth/user"](true);
          core.loader.hide();

          await core.alert.show({
            title: "알림",
            body: "회원정보가 수정되었습니다.",
          });
          await this.$router.back();
        } catch (e) {
          core.loader.hide();
          this.errorSubmit(e, this.formConvertField);
        }
        this.processing = false;
      }
    },
  },
});

import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import NotSupportBrowser from "@/views/errors/NotSupportBrowser.vue";
import NotFound from "@/views/errors/NotFound.vue";
import Logout from "@/views/Logout.vue";
import core from "@/core";
import store from "@/store";
import Login from "@/views/Login.vue";
import Constant from "@/store/constant";
import Settings from "@/views/Settings.vue";
import UserMyInfoView from "@/views/user/MyInfoView.vue";
import UserMyInfoEdit from "@/views/user/MyInfoEdit.vue";
import UserMyPassword from "@/views/user/MyPassword.vue";
import UserMyWithdraw from "@/views/user/MyWithdraw.vue";
import Disease from "@/views/Disease.vue";
import DiseaseAnalysisRequest from "@/views/disease/AnalysisRequest.vue";
import DiseaseManager from "@/views/DiseaseManager.vue";
import DiseaseManagerAnnotation from "@/views/DiseaseManagerAnnotation.vue";
import DiseaseCamera from "@/views/DiseaseCamera.vue";
import DiseaseArea from "@/views/DiseaseArea.vue";
import DiseaseInfo from "@/views/DiseaseInfo.vue";
import DiseaseCameraHelp from "@/views/DiseaseCameraHelp.vue";

Vue.use(VueRouter);

const appName = Constant.appName;

export enum MenuType {
  NAVIGATION = "navigation",
  BACK = "back",
  TITLE = "title",
  NONE = "none",
  BOTTOMBAR = "bottombar",
}

export interface SubMenu {
  text: SubTextMenu | null | undefined;
  icon: SubIconMenu | null | undefined;
}

export interface SubIconMenu {
  visible: boolean;
  list: Menu[] | null;
}

export interface SubTextMenu {
  visible: boolean;
  width: number;
  list: Menu[] | null;
}

export interface Menu {
  icon: string;
  text: string;
  id: string;
  visible: boolean;
  event: boolean;
}

export interface RouteMeta {
  title: string;
  roles: string[] | null;
  menuType: MenuType;
  pageStack: boolean;
  subMenu: SubMenu | null;
  scrollable: boolean | null;
}

const routes: Array<RouteConfig> = [
  {
    path: "/",
    redirect: "/disease",
    meta: {
      title: "",
      roles: null,
      menuType: MenuType.NONE,
      pageStack: false,
      subMenu: null,
      scrollable: true,
    } as RouteMeta,
  },
  {
    path: "/disease",
    name: "disease",
    component: Disease,
    meta: {
      title: "병해 영상진단",
      roles: null,
      menuType: MenuType.NAVIGATION,
      pageStack: false,
      subMenu: null,
      scrollable: true,
    } as RouteMeta,
  },
  {
    path: "/disease/analysis-request",
    name: "diseaseAnalysisRequest",
    component: DiseaseAnalysisRequest,
    meta: {
      title: "병해 분석요청",
      roles: null,
      menuType: MenuType.BACK,
      pageStack: false,
      subMenu: null,
      scrollable: true,
    } as RouteMeta,
  },
  {
    path: "/disease/camera",
    name: "diseaseCamera",
    component: DiseaseCamera,
    meta: {
      title: "병해 영상진단",
      roles: null,
      menuType: MenuType.BACK,
      pageStack: false,
      subMenu: null,
      scrollable: true,
    } as RouteMeta,
  },
  {
    path: "/disease/camera/help",
    name: "diseaseCameraHelp",
    component: DiseaseCameraHelp,
    meta: {
      title: "병해 영상진단 도움말",
      roles: null,
      menuType: MenuType.BACK,
      pageStack: false,
      subMenu: null,
      scrollable: true,
    } as RouteMeta,
  },
  {
    path: "/disease/area",
    name: "diseaseArea",
    component: DiseaseArea,
    meta: {
      title: "병해 영상진단",
      roles: null,
      menuType: MenuType.BACK,
      pageStack: false,
      subMenu: null,
      scrollable: true,
    } as RouteMeta,
  },
  {
    path: "/disease-info",
    name: "diseaseInfo",
    component: DiseaseInfo,
    meta: {
      title: "병해정보",
      roles: null,
      menuType: MenuType.NAVIGATION,
      pageStack: false,
      subMenu: null,
      scrollable: true,
    } as RouteMeta,
  },
  {
    path: "/disease-manager",
    name: "diseaseManager",
    component: DiseaseManager,
    meta: {
      title: "병해 영상판독(전문가)",
      roles: ["manager"],
      menuType: MenuType.NAVIGATION,
      pageStack: false,
      subMenu: null,
      scrollable: true,
    } as RouteMeta,
  },
  {
    path: "/disease-manager/annotation",
    name: "diseaseManagerAnnotation",
    component: DiseaseManagerAnnotation,
    meta: {
      title: "병해 영상판독 주석도구",
      roles: ["manager"],
      menuType: MenuType.BACK,
      pageStack: false,
      subMenu: null,
      scrollable: true,
    } as RouteMeta,
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: {
      title: "로그인",
      roles: null,
      menuType: MenuType.BACK,
      pageStack: false,
      subMenu: null,
      scrollable: true,
    } as RouteMeta,
  },
  {
    path: "/logout",
    name: "logout",
    component: Logout,
    meta: {
      title: "로그아웃",
      roles: ["manager"],
      menuType: MenuType.NONE,
      pageStack: false,
      subMenu: null,
      scrollable: true,
    } as RouteMeta,
  },

  {
    path: "/settings",
    name: "settings",
    component: Settings,
    meta: {
      title: "설정",
      roles: ["manager"],
      menuType: MenuType.NAVIGATION,
      pageStack: false,
      subMenu: null,
      scrollable: true,
    } as RouteMeta,
  },
  {
    path: "/user/me/info",
    name: "userMyInfoView",
    component: UserMyInfoView,
    meta: {
      title: "회원정보",
      roles: ["manager"],
      menuType: MenuType.BACK,
      pageStack: false,
      scrollable: true,
      subMenu: {
        icon: {
          visible: true,
          list: [
            {
              icon: "",
              text: "수정",
              id: "edit",
              visible: true,
              event: false,
            },
          ],
        },
        text: null,
      },
    } as RouteMeta,
  },
  {
    path: "/user/me/info/edit",
    name: "userMyInfoEdit",
    component: UserMyInfoEdit,
    meta: {
      title: "회원정보",
      roles: ["manager"],
      menuType: MenuType.BACK,
      pageStack: false,
      subMenu: null,
      scrollable: true,
    } as RouteMeta,
  },
  {
    path: "/user/me/password",
    name: "userMyPassword",
    component: UserMyPassword,
    meta: {
      title: "비밀번호 변경",
      roles: ["manager"],
      menuType: MenuType.BACK,
      pageStack: false,
      subMenu: null,
      scrollable: true,
    } as RouteMeta,
  },
  {
    path: "/user/me/withdraw",
    name: "userMyWithdraw",
    component: UserMyWithdraw,
    meta: {
      title: "회원탈퇴",
      roles: ["manager"],
      menuType: MenuType.BACK,
      pageStack: false,
      subMenu: null,
      scrollable: true,
    } as RouteMeta,
  },
  {
    path: "/error/not-support-browser",
    name: "notSupportBrowser",
    component: NotSupportBrowser,
    meta: {
      title: "",
      roles: null,
      menuType: MenuType.NONE,
      pageStack: false,
      subMenu: null,
      scrollable: true,
    } as RouteMeta,
  },
  {
    path: "*",
    name: "notFound",
    component: NotFound,
    meta: {
      title: "",
      roles: null,
      menuType: MenuType.NONE,
      pageStack: false,
      subMenu: null,
      scrollable: true,
    } as RouteMeta,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

const routeMap = new Map();
routes.forEach((data) => {
  if (data.children != null) {
    const path = data.path;
    data.children.forEach((data) => {
      routeMap.set(path + data.path, data);
    });
  } else {
    routeMap.set(data.path, data);
  }
});

async function routerResult(next, path: string) {
  next({ path: path });
  const route = routeMap.get(path);
  // console.log("routerResult path : ", path);
  return route.meta;
}

async function beforeEach(to, from, next) {
  const user = await store.getters["auth/user"]();
  if (user) {
    // 로그인 중인 사용자
    if (to.meta.roles != null) {
      // 로그인 사용자 접근 가능 페이지일 경우 이동
      next();
      // console.log("로그인 사용자 next");
    } else {
      // 비로그인 사용자 접근 가능 페이지일 경우 이동
      if (to.path.toLowerCase() === "/login") {
        if (core.utils.validate.isBlank(to.query.v)) {
          // 로그인중인 사용자가 로그인 페이지 접근시 이동
          return await routerResult(next, "/disease");
        } else {
          // 로그인중인 사용자가 자동인증 정보파라미터를 포함해서 들어왔을 경우 로그인 페이지로 이동
          next();
        }
      } else {
        next();
      }
      // console.log("로그인 사용자 next, 페이지 권한 필요없음");
    }
  } else {
    if (to.meta.roles != null) {
      // 로그인 사용자 접근 가능 페이지일 경우 메인 페이지로 이동
      if (from.path !== "/login") {
        return await routerResult(next, "/login");
      } else {
        return await routerResult(next, "/");
      }
    } else {
      next();
      // console.log("비로그인 사용자 next, 페이지 권한 필요없음");
    }
  }

  return to.meta;
}

let first = true;

router.beforeEach(async (to, from, next) => {
  // console.log("from   : ", from);
  // console.log("to     : ", to);
  // console.log("from " + from.path + " -> to : " + to.path);

  if (store.state.app.printClass) {
    await store.dispatch("app/clearPrint");
  }

  if (store.state.topToolbar.changedStyle) {
    await store.dispatch("topToolbar/changeOrgStyle");
  }

  if (from.path === "/login" && to.path === "/login") {
    window.history.back();
    return;
  }

  if (first) {
    first = false;
    if (core.utils.redirectWebviewBrowser(router)) {
      return;
    }
  }
  // const nodeList = document.querySelectorAll(".viewer-container");
  // if (nodeList != null && nodeList.length > 0) {
  //   nodeList.forEach((node) => {
  //     node.remove();
  //   });
  // }

  const meta = await beforeEach(to, from, next);
  if (meta != null) {
    if (meta.title != null) {
      if (meta.title.length > 0) {
        document.title = `${appName} - ${meta.title}`;
      } else {
        document.title = appName;
      }
    }
    if (meta.menuType !== MenuType.NONE) {
      await store.dispatch("topToolbar/changeTitle", meta.title);
    }

    if (!meta.scrollable) {
      document.documentElement.classList.add("overflow-y-hidden");
    } else {
      document.documentElement.classList.remove("overflow-y-hidden");
    }
  }
});

export default router;




























import CoreKakaoMap from "@/core/core-kakao-map";
import DiseaseService from "@/services/disease/disease.service";
import mixins from "vue-typed-mixins";
import core from "@/core";
import Constant from "@/store/constant";
import UpdateEvent from "@/models";
import { UpdateEventType } from "@/types";
import ChartjsUtils from "@/utils/chartjs-utils";

declare let kakao: any;

export default mixins().extend({
  name: "DiseaseAreaCountMapComponent",
  props: {
    properties: {
      type: Object,
      default: () => {
        return {
          viewMode: false,
          visibleMyLocationButton: false,
          cropCode: null as any,
          analysisStatus: null as any,
          updateEvent: null as UpdateEvent | null,
          markerClick: null as any,
        };
      },
    },
  },
  data() {
    return {
      viewMode: false,
      markerList: null as [] | null,
      kakaoMap: null as CoreKakaoMap | null,
      map: {
        default: {
          address: "선택된 위치가 없습니다",
          latitude: 37.5653888,
          longitude: 126.9783866,
          level: 12,
        },
      },
      findLocation: false,
      sidoAreaList: null as any,
      areaList: null as any,
      selectedMarker: null as any,
      sidoMapLevel: 10,
    };
  },
  mounted() {
    this.$nextTick(() => {
      const defaultLocation = this.map.default;
      const location = {
        address1: defaultLocation.address,
        latitude: defaultLocation.latitude,
        longitude: defaultLocation.longitude,
        level: defaultLocation.level,
      };
      // console.log("cropCode : ", this.properties.cropCode);
      if (this.properties.viewMode != null) {
        this.viewMode = this.properties.viewMode;
      } else {
        this.viewMode = false;
      }

      this.initMap(location);
      this.getAreaData();
    });
  },
  watch: {
    "properties.cropCode"(val) {
      // console.log("cropCode : ", val);
      this.getAreaData();
    },
    "properties.markerClick"(item) {
      //console.log("item : ", item);
      let marker = null as any;
      if (this.markerList != null) {
        this.markerList.some((_marker: any) => {
          if (_marker.id === item.id) {
            marker = _marker;
            return true;
          }
        });
      }
      if (marker != null) {
        this.markerClick(marker);
      }
    },
    selectedMarker(marker) {
      if (marker != null) {
        if (this.markerList != null) {
          this.markerList.some((marker: any) => {
            if (marker.chartOverlay != null) {
              marker.chartOverlay.setMap(null);
              marker.chartOverlay = null;
              return true;
            }
          });
        }
        const kakaoMap = this.kakaoMap as CoreKakaoMap;
        const lat = marker.getPosition().getLat();
        const lng = marker.getPosition().getLng();
        const overlay = kakaoMap.addChartOverlay(lat, lng);
        const area = marker.area;
        const properties = this.properties;
        //console.log("area : ", area);
        marker.chartOverlay = overlay;
        ChartjsUtils.createPieChart(overlay.id, area.sggNameList, area.countList, (label) => {
          area.sggNameList.some((sggName, index) => {
            if (sggName === label) {
              const sggCode = area.sggCodeList[index];
              //console.log("sggCode : ", sggCode);
              properties.updateEvent = new UpdateEvent(UpdateEventType.UPDATE, "", {
                sidoCode: area.sidoCode,
                sggCode: sggCode,
                sidoName: area.sidoName,
                sggName: sggName,
                cropCode: area.cropCode,
              });
              return true;
            }
          });
        });
        //console.log("overlay : ", overlay);
      }
    },
  },
  methods: {
    async getAreaData() {
      if (this.properties.cropCode != null) {
        let params = null as any;
        if (this.properties.analysisStatus) {
          params = { analysisStatus: this.properties.analysisStatus };
        }
        this.areaList = (await DiseaseService.getAreaList(this.properties.cropCode, params)) as any;
        //console.log("areaList : ", this.areaList);

        const sidoAreaMap = {} as any;
        this.areaList.forEach((area) => {
          let sidoArea = sidoAreaMap[area.sidoCode];
          if (sidoArea == null) {
            sidoArea = {
              sidoCode: area.sidoCode,
              sidoName: area.sidoName,
              isSidoType: true,
              sggCode: area.sggCode,
              sggName: area.sggName,
              latitudeList: [],
              longitudeList: [],
              cropCode: area.cropCode,
              sggCodeList: [],
              sggNameList: [],
              countList: [],
              count: 0,
              analysisStatus: area.analysisStatus,
            };
          }
          sidoArea.count += area.count;
          sidoArea.sggCodeList.push(area.sggCode);
          sidoArea.sggNameList.push(area.sggName);
          sidoArea.countList.push(area.count);
          sidoArea.latitudeList.push(area.latitude);
          sidoArea.longitudeList.push(area.longitude);
          sidoAreaMap[area.sidoCode] = sidoArea;
        });

        const sidoAreaList = [] as any;
        for (const key of Object.keys(sidoAreaMap)) {
          const area = sidoAreaMap[key];

          let latitude = 0;
          area.latitudeList.forEach((_latitude) => {
            latitude += _latitude;
          });
          latitude = latitude / area.latitudeList.length;
          area.latitude = latitude;

          let longitude = 0;
          area.longitudeList.forEach((_longitude) => {
            longitude += _longitude;
          });
          longitude = longitude / area.longitudeList.length;
          area.longitude = longitude;
          sidoAreaList.push(area);
        }
        this.sidoAreaList = sidoAreaList;
        // let isTest = true;
        // let isTest2 = true;
        // sidoAreaList.forEach((sidoArea) => {
        //   if (isTest) {
        //     sidoArea.count = 999;
        //     isTest = false;
        //   } else if (isTest2) {
        //     sidoArea.count = 9999;
        //     isTest2 = false;
        //   }
        // });
        // console.log("sidoAreaMap : ", sidoAreaMap);

        this.redrawMarkers(this.properties.cropCode, true);
      }
    },
    moveCurrentPosition() {
      if (this.findLocation) {
        this.findLocation = false;
      } else {
        this.findLocation = true;

        const kakaoMap = this.kakaoMap as CoreKakaoMap;
        core.location
          .currentPosition()
          .then((location: any) => {
            //console.log('location : ', location);
            this.map.default.latitude = location.latitude;
            this.map.default.longitude = location.longitude;

            if (this.findLocation) {
              this.findLocation = false;
              kakaoMap
                .changeAddressByLocation(location.latitude, location.longitude, false)
                .then((data) => {
                  if (data != null) {
                    kakaoMap.setCenterAndLevel(data.latitude, data.longitude, 8);
                  }
                })
                .catch((reason) => {
                  console.log(reason);
                });
            }
          })
          .catch((reason) => {
            this.findLocation = false;
          });
      }
    },
    async redrawMarkers(cropCode, moveCenter) {
      if (this.markerList != null) {
        this.markerList.forEach((marker) => {
          this.kakaoMap?.clearMarker(marker);
        });
        this.selectedMarker = null;
      }
      if (moveCenter == null) {
        moveCenter = true;
      }

      // let params = null as any;
      // if (this.properties.analysisStatus) {
      //   params = { analysisStatus: this.properties.analysisStatus };
      // }
      // const areaList = (await DiseaseService.getAreaList(cropCode, params)) as any;

      const sidoMapLevel = this.sidoMapLevel;
      const mapLevel = (this.kakaoMap as CoreKakaoMap).map.getLevel();
      const areaList = mapLevel > sidoMapLevel ? this.sidoAreaList : this.areaList;
      //console.log("areaList : ", areaList);
      const markerList = [] as any;
      const bounds = new kakao.maps.LatLngBounds();
      areaList.forEach((area) => {
        const text =
          mapLevel > sidoMapLevel ? `${area.sidoName}` : `${area.sidoName} ${area.sggName}`;
        const properties = this.properties;
        const kakaoMap = this.kakaoMap;
        const markerClick = this.markerClick;
        const marker = kakaoMap?.addMarkerAndOverlay2(
          area.latitude,
          area.longitude,
          text,
          area.count,
          () => {
            markerClick(marker);
          }
        );
        marker.area = area;

        markerList.push(marker);
        bounds.extend(marker.getPosition());
      });
      this.markerList = markerList;

      if (moveCenter) {
        if (areaList.length > 0) {
          this.kakaoMap?.map.setBounds(bounds, 100, 50, 0, 50);
        } else {
          const defaultMap = this.map.default;
          this.kakaoMap?.setCenterAndLevel(
            defaultMap.latitude,
            defaultMap.longitude,
            defaultMap.level
          );
        }
      }
    },
    markerClick(marker) {
      //console.log("marker : ", marker);
      // console.log("type : ", type, ", id : ", id, ", marker : ", marker);

      const area = marker.area;
      const properties = this.properties;
      const kakaoMap = this.kakaoMap as CoreKakaoMap;

      if (area.isSidoType) {
        this.selectedMarker = marker;
      } else {
        this.selectedMarker = null;

        properties.updateEvent = new UpdateEvent(UpdateEventType.UPDATE, "", {
          sidoCode: area.sidoCode,
          sggCode: area.sggCode,
          sidoName: area.sidoName,
          sggName: area.sggName,
          cropCode: area.cropCode,
        });
      }

      if (marker.overlay != null) {
        marker.overlay.setMap(null);
      }

      if (marker.countOverlay != null) {
        marker.countOverlay.setMap(null);
      }
      marker.setMap(null);

      marker.setMap(kakaoMap.map);
      if (marker.overlay != null) {
        marker.overlay.setMap(kakaoMap.map);
      }
      if (marker.countOverlay != null) {
        marker.countOverlay.setMap(kakaoMap.map);
      }
    },
    initMap(params) {
      //console.log("init map");
      const mapData = this.map;

      const mapElement = this.$refs.map;

      const properties = this.properties;
      const redrawMarkers = this.redrawMarkers;

      const options = {
        center: new kakao.maps.LatLng(params.latitude, params.longitude),
        level: params.level,
      } as any;
      if (this.viewMode) {
        options.disableDoubleClick = true;
        options.disableDoubleClickZoom = true;
      }
      const preItem = {
        level: params.level,
        sido: false,
      };
      const vm = this as any;
      const sidoMapLevel = this.sidoMapLevel;
      const kakaoMap = (this.kakaoMap = new CoreKakaoMap(
        {
          map: {
            element: mapElement,
            option: options,
          },
          callback: {
            idle(map) {
              // // 11레벨보다 클경우 시도별 마커처리
              // var message =
              //   "지도의 중심좌표는 " +
              //   map.getCenter().toString() +
              //   " 이고," +
              //   "확대 레벨은 " +
              //   map.getLevel() +
              //   " 레벨 입니다.";
              // console.log(message);

              if (preItem.level != map.getLevel()) {
                //console.log("change marker");
                if (properties.cropCode != null) {
                  if (map.getLevel() > sidoMapLevel) {
                    if (!preItem.sido) {
                      console.log("redrawMarkers");
                      redrawMarkers(properties.cropCode, false);
                    }
                    preItem.sido = true;
                  } else {
                    if (preItem.sido) {
                      console.log("redrawMarkers");
                      redrawMarkers(properties.cropCode, false);
                    }
                    preItem.sido = false;
                  }
                }
              }
            },
            click(e) {
              //console.log("click");
              const selectedMarker = vm.selectedMarker;
              if (selectedMarker != null) {
                if (selectedMarker.chartOverlay != null) {
                  selectedMarker.chartOverlay.setMap(null);
                  selectedMarker.chartOverlay = null;
                }
                vm.selectedMarker = null;
              }
            },
          },
        },
        core,
        Constant.kakao.restApiKey
      ));

      if (this.viewMode) {
        kakaoMap.map.setDraggable(false);
        kakaoMap.map.setZoomable(false);
      }
    },
  },
});

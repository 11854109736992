




















































import mixins from "vue-typed-mixins";
import MixinsPageForm from "@/mixins/page-form";
import store from "@/store";
import core from "@/core";

export default mixins(MixinsPageForm).extend({
  name: "Settings",
  components: {},
  mounted() {
    this.$nextTick(() => {});
  },
  methods: {
    async logout() {
      const result = await core.alert.show({
        title: "확인",
        body: "현재 인증된 정보가 해제됩니다.<br>계속 진행하시겠습니까?",
        showCancelButton: true,
      });
      if (result === "confirm") {
        await this.$router.push("/logout");
      }
    },
  },
});

class ChartJsUtils {
  createPieChart(id, labelList, dataList, callback) {
    const el = document.getElementById(id) as any;
    if (el == null) return;
    const ctx = el.getContext("2d");
    const Chart = (window as any).Chart;
    const ChartDataLabels = (window as any).ChartDataLabels;
    Chart.register(ChartDataLabels);

    const backgroundColor = [] as any;
    const colorList = [
      "rgba(255, 99, 132, 1)",
      "rgba(54, 162, 235, 1)",
      "rgba(255, 206, 86, 1)",
      "rgba(75, 192, 192, 1)",
      "rgba(153, 102, 255, 1)",
      "rgba(255, 159, 64, 1)",
      "#607d8b",
      "#ff5722",
      "#673ab7",
      "#f44336",
      "#795548",
      "#3f51b5",
      "#03a9f4",
      "#00bcd4",
      "#009688",
    ];

    let colorIndex = 0;
    labelList.forEach((label) => {
      backgroundColor.push(colorList[colorIndex]);
      colorIndex++;
      if (colorIndex >= colorList.length) {
        colorIndex = 0;
      }
    });
    const chart = new Chart(ctx, {
      type: "pie",
      // type: "doughnut",
      data: {
        labels: labelList,
        datasets: [
          {
            data: dataList,
            backgroundColor: backgroundColor,
            // borderColor: [
            //   "rgba(255, 99, 132, 1)",
            //   "rgba(54, 162, 235, 1)",
            //   "rgba(255, 206, 86, 1)",
            //   "rgba(75, 192, 192, 1)",
            //   "rgba(153, 102, 255, 1)",
            //   "rgba(255, 159, 64, 1)"
            // ],
            borderWidth: 0.5,

            datalabels: {
              anchor: "end",
              align: "start",
              backgroundColor: "#fafafa",
              borderWidth: 0,
            },
          },
        ],
      },
      options: {
        onClick: function (e, points) {
          // console.log("e : ", e, ", points : ", points);
          if (points.length > 0) {
            const point = points[0];
            const label = e.chart.data.labels[point.index];
            if (callback != null) {
              callback(label);
            } else {
              console.log("not found callback. label: ", label);
            }
            // console.log("label : ", label);
          }
        },
        responsive: true,
        plugins: {
          tooltip: {
            enabled: false,
            // callbacks: {
            //   label: function (context) {
            //     let label = context.dataset.label || "";
            //
            //     if (label) {
            //       label += ": ";
            //     }
            //     if (context.parsed.y !== null) {
            //       label += new Intl.NumberFormat("en-US", {
            //         style: "currency",
            //         currency: "USD",
            //       }).format(context.parsed.y);
            //     }
            //     console.log("label : ", label);
            //     // return label;
            //     return null;
            //   },
            // },
          },
          legend: {
            display: false,
            position: "bottom",
          },
          datalabels: {
            // color: ["#fff", "#fff", "#fff", "#fff"],
            color: ["#333333", "#333333", "#333333", "#333333"],
            borderWidth: 2,
            borderColor: ["#919191", "#919191", "#919191", "#919191"],
            borderRadius: 5,
            // anchor: "end",
            font: {
              size: 12,
              weight: "bold",
            },
            formatter: function (value, context) {
              const label = context.chart.data.labels[context.dataIndex];
              return `${label} ${value}건`;
              // return value > 2 ? value + "%" : null; //data가 10 미만이면 숫자 값은 출력되지 않습니다.
            },
          },
        },
      },
    });
  }
}

export default new ChartJsUtils();





























import mixins from "vue-typed-mixins";
import MixinsModal from "@/mixins/modal";
import UpdateEvent from "@/models";
import { ComponentProperties } from "@/mixins/component";
import { UpdateEventType } from "@/types";

export default mixins(MixinsModal).extend({
  name: "SelectCropModal",
  props: {
    properties: {
      type: Object as () => ComponentProperties,
      default: () => {
        return {
          visible: false,
          params: {
            itemList: null as any,
          },
          // 업데이트 이벤트 처리용 변수
          updateEvent: null as UpdateEvent | null,
        } as ComponentProperties;
      },
    },
  },
  data: () => ({
    maxHeight: 300,
  }),
  mounted() {
    this.$nextTick(async () => {
      const height = this.app.size.height - 56;
      if (height > 400) {
        this.maxHeight = 400;
      } else {
        this.maxHeight = height;
      }
      //console.log("itemList : ", this.properties.params.itemList);
    });
  },
  watch: {},
  methods: {
    select(item) {
      //console.log("select item : ", item);
      this.close(UpdateEventType.CONFIRM, item);
    },
  },
});

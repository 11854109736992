import qs from "qs";
import core from "@/core";
import { UserModel } from "@/models/user/user.model";

class UserService {
  register(params) {
    const strParam = JSON.stringify(params);
    return new Promise((resolve, reject) => {
      core.http
        .postJson(`/api/v1/user/register`, strParam)
        .then((data) => {
          //console.log('result: ', data);
          resolve(data);
        })
        .catch((reason) => {
          //console.log('error: ', reason);
          reject(reason);
        });
    });
  }

  update(params) {
    const strParam = JSON.stringify(params);
    return new Promise((resolve, reject) => {
      core.http
        .putJson(`/api/user/me`, strParam)
        .then((data) => {
          //console.log('result: ', data);
          resolve(data);
        })
        .catch((reason) => {
          //console.log('error: ', reason);
          reject(reason);
        });
    });
  }

  login(params) {
    const strParam = qs.stringify(params);
    return new Promise((resolve, reject) => {
      core.http
        .post(`/api/login`, strParam)
        .then((data) => {
          //console.log('result: ', data);
          resolve(data);
        })
        .catch((reason) => {
          //console.log('error: ', reason);
          reject(reason);
        });
    });
  }

  socialRegister(params) {
    const strParam = JSON.stringify(params);
    return new Promise((resolve, reject) => {
      core.http
        .postJson(`/api/social/register`, strParam)
        .then((data) => {
          //console.log('result: ', data);
          resolve(data);
        })
        .catch((reason) => {
          //console.log('error: ', reason);
          reject(reason);
        });
    });
  }

  socialLogin(registrationId: string, addProvider: boolean | null, params) {
    if (addProvider) {
      params.addProvider = addProvider;
    }
    const strParam = qs.stringify(params);
    return new Promise((resolve, reject) => {
      core.http
        .post(`/api/social/${registrationId}`, strParam)
        .then((data) => {
          //console.log('result: ', data);
          resolve(data);
        })
        .catch((reason) => {
          //console.log('error: ', reason);
          reject(reason);
        });
    });
  }

  logout() {
    return new Promise((resolve, reject) => {
      core.http
        .getJson(`/api/logout`)
        .then((data) => {
          // console.log('result: ', data);
          resolve(data);
        })
        .catch((reason) => {
          // console.log('error: ', reason);
          reject(reason);
        });
    });
  }

  getMyInfo(): Promise<UserModel> {
    return new Promise((resolve, reject) => {
      core.http
        .getJson(`/api/user/me`, { ignoreAlertModal: true })
        .then((data: any) => {
          //console.log('result: ', data);
          if (data.user != null) {
            resolve(data.user as UserModel);
          } else {
            reject({ message: "not found item" });
          }
        })
        .catch((reason) => {
          //console.log('error: ', reason);
          reject(reason);
        });
    });
  }

  updateMobileDeviceInfo(params: {
    crudType: string;
    deviceToken: string;
    timezone: string | null;
    locale: string | null;
    manufacturer: string | null;
    model: string | null;
    product: string | null;
    hardware: string | null;
    os: string | null;
    versionSdk: number | null;
    versionRelease: string | null;
    packageName: string | null;
    versionCode: number | null;
    versionName: string | null;
  }) {
    const strParam = JSON.stringify(params);
    return new Promise((resolve, reject) => {
      core.http
        .postJson(`/api/user/me/mobile-device`, strParam)
        .then((data) => {
          //console.log('result: ', data);
          resolve(data);
        })
        .catch((reason) => {
          //console.log('error: ', reason);
          reject(reason);
        });
    });
  }

  getSocialToken(token: string) {
    return new Promise((resolve, reject) => {
      core.http
        .getJson(`/api/social/token`, null, { value: token })
        .then((data) => {
          //console.log('result: ', data);
          if (data.social != null) {
            resolve(data.social);
          } else {
            reject({ message: "not found item" });
          }
        })
        .catch((reason) => {
          //console.log('error: ', reason);
          reject(reason);
        });
    });
  }

  createPhoneVerify(phone: string) {
    const strParam = JSON.stringify({
      phone: phone,
    });
    return new Promise((resolve, reject) => {
      core.http
        .postJson(`/api/user/phone-verify`, strParam)
        .then((data) => {
          //console.log('result: ', data);
          resolve(data.token);
        })
        .catch((reason) => {
          //console.log('error: ', reason);
          reject(reason);
        });
    });
  }

  checkPhoneVerify(token: string, phone: string, certificationNumber: string) {
    const strParam = JSON.stringify({
      token: token,
      phone: phone,
      certificationNumber: certificationNumber,
    });
    return new Promise((resolve, reject) => {
      core.http
        .postJson(`/api/user/phone-verify/check`, strParam)
        .then((data) => {
          //console.log('result: ', data);
          resolve(data);
        })
        .catch((reason) => {
          //console.log('error: ', reason);
          reject(reason);
        });
    });
  }

  updatePassword(currentPassword: string, newPassword: string, newConfirmPassword: string) {
    const strParam = JSON.stringify({
      currentPassword: currentPassword,
      newPassword: newPassword,
      newConfirmPassword: newConfirmPassword,
    });
    return new Promise((resolve: any, reject) => {
      core.http
        .putJson(`/api/user/me/password`, strParam)
        .then((data) => {
          //console.log('result: ', data);
          resolve(data);
        })
        .catch((reason) => {
          //console.log('error: ', reason);
          reject(reason);
        });
    });
  }

  createProfileImage(formData: FormData) {
    return new Promise((resolve, reject) => {
      core.http
        .post(`/api/user/me/profile-image`, formData, {
          headers: core.http.headers.form,
        })
        .then((data) => {
          if (data.fileDetailId != null) {
            resolve(data.fileDetailId);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason) => {
          reject(reason);
        });
    });
  }

  withdraw() {
    return new Promise((resolve, reject) => {
      core.http
        .delete(`/api/user/me/withdraw`)
        .then((data) => {
          resolve(data);
        })
        .catch((reason) => {
          reject(reason);
        });
    });
  }
}

export default new UserService();

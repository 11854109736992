// Polyfills
import "core-js/stable";
import "regenerator-runtime/runtime";

import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VuePageStack from "@/plugins/vue-page-stack";

import { extend, localize } from "vee-validate";
import { email, max, min, required } from "vee-validate/dist/rules";
import core from "@/core";
import TopToolbar from "@/components/core/TopToolbar.vue";
import SelectSearchModal from "@/modals/core/SelectSearchModal.vue";
import SelectCameraModal from "@/modals/core/SelectCameraModal.vue";
import Toast from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";

import Constant from "@/store/constant";
import ComponentTemplate from "@/components/core/ComponentTemplate.vue";

import "viewerjs/dist/viewer.css";
import Viewer from "v-viewer";

Vue.config.productionTip = false;

// 공통 유틸 초기화
core.init(store, router);

const options = {
  timeout: false,
  maxToasts: 1,
};

Vue.use(Toast, options);

Vue.component("top-toolbar", TopToolbar);

// 아이템 검색 및 선택 모달
Vue.component("select-search-modal", SelectSearchModal);

// 카메라 선택 모달
Vue.component("select-camera-modal", SelectCameraModal);

Vue.component("scc-component-template", ComponentTemplate);

Vue.use(Viewer);

if (!("toJSON" in Error.prototype))
  Object.defineProperty(Error.prototype, "toJSON", {
    value: function () {
      const alt = {};

      Object.getOwnPropertyNames(this).forEach(function (key) {
        try {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          alt[key] = this[key];
        } catch (e) {
          alt[key] = e;
        }
      }, this);

      return alt;
    },
    configurable: true,
    writable: true,
  });

Vue.config.errorHandler = (error, vm, info) => {
  console.log("error : ", error);
  //core.http.sendError(error);
};

Vue.prototype.$eventBus = new Vue();

// page stack(이전 페이지 저장 처리)
Vue.use(VuePageStack, {
  router,
  routerBeforeTask: store.state.app.routerBeforeTask,
  name: "vue-page-stack",
  keyName: "stackKey",
});

// validate
extend("required", required);
extend("min", min);
extend("max", max);
extend("email", email);
extend("phone", {
  validate(value) {
    return core.utils.validate.phone(value);
  },
  message: "휴대전화 형식이 올바르지 않습니다",
});
extend("password", {
  params: ["target"],
  validate(value, params) {
    const target = (params as any).target;
    // console.log('value : ', value, ', target : ', target);
    return value === target;
  },
  message: "비밀번호 확인이 일치하지 않습니다",
});

localize("ko", {
  messages: Constant.validate.language.korea.messages,
  names: {
    user_username: "아이디 ",
    user_password: "비밀번호 ",
    user_confirmPassword: "비밀번호 확인  ",
    user_email: "이메일 ",
    user_name: "이름 ",
    user_phone: "휴대전화 ",
    user_certificationNumber: "인증번호 ",
    user_currentPassword: "현재 비밀번호 ",
    memo: "메모 ",
    disease_cropCode: "작물 ",
    disease_address: "수집지역 ",
    disease_farmerName: "농장주 ",
    disease_authorEmail: "이메일 ",
    disease_kindCode: "온실형태 ",
    disease_date: "날짜 ",
    disease_diseaseName: "병해이름 ",
    disease_diseaseStep: "병해단계 ",
    disease_cropPosition: "작물상 병해 위치 ",
    disease_housePosition: "온실내 병해 위치 ",
    disease_image: "이미지 ",
    disease_departmentId: "요청 부서 ",
  },
});

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");

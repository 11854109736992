import Vue from "vue";
import Vuex from "vuex";
import { auth } from "@/store/modules/auth";
import { topToolbar } from "@/store/modules/top-toolbar";
import { app } from "@/store/modules/app";
import { storage } from "@/store/modules/storage";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    topToolbar,
    app,
    storage,
  },
});





























































import mixins from "vue-typed-mixins";
import MixinsPageForm from "@/mixins/page-form";
import HintView from "@/components/core/HintView.vue";
import UpdateEvent from "@/models";
import CodeService from "@/services/code/code.service";
import MixinsStorage from "@/mixins/single/storage";
import SelectCropModal from "@/modals/disease/SelectCropModal.vue";
import { UpdateEventType } from "@/types";
import MixinsTable from "@/mixins/single/table";
import MixinsScroll from "@/mixins/single/scroll";
import DiseaseService from "@/services/disease/disease.service";
import core from "@/core";

export default mixins(MixinsPageForm, MixinsStorage, MixinsTable, MixinsScroll).extend({
  name: "DiseaseManager",
  components: { SelectCropModal, HintView },
  data: () => ({
    scrollTop: 0,
    storageItems: {
      type: "disease",
    },
    table: {
      body: document.documentElement,
      defaultColumns: [],
    },
    modal: {
      selectCrop: {
        visible: false,
        params: {
          itemList: null as any,
        },
        updateEvent: null as UpdateEvent | null,
      },
    },
    searchColumns: {
      status: "UNKNOWN",
      resultStatus: "UNKNOWN",
    } as any,
    cropCodeList: [] as any,
    selectedCropCodeIndex: null as any,
    selectedCropItem: null as any,
    diseaseList: [] as any,
  }),
  mounted() {
    this.$nextTick(async () => {
      const cropCodeList = (await CodeService.getCropCodeList()) as any;
      this.cropCodeList = cropCodeList;
      //console.log("cropCodeList : ", cropCodeList);
      await this.storageWait();
      const items = this.getStorageItems();
      if (items.cropCodeItem == null) {
        cropCodeList.some((cropCode, index) => {
          if (cropCode.enName == "tomato") {
            // this.selectedCropItem = cropCode;
            this.selectedCropCodeIndex = index;
            return true;
          }
        });
      } else {
        // this.selectedCropItem = items.cropCodeItem;
        const cropCodeItem = items.cropCodeItem;
        cropCodeList.some((cropCode, index) => {
          if (cropCode.code == cropCodeItem.code) {
            // this.selectedCropItem = cropCode;
            this.selectedCropCodeIndex = index;
            return true;
          }
        });
      }
      //console.log("cropItem : ", this.selectedCropItem);

      // 테이블 초기화
      this.initTable({
        service: DiseaseService,
        serviceFunctionKey: "getTable",
        itemHeight: 56,
      });
      // this.searchColumns.cropCode = this.selectedCropItem.code;
      // this.loadTable(this.searchColumns);
    });
  },
  watch: {
    "scroll.scrollTop"(scrollTop) {
      //console.log("scrollTop : ", scrollTop);
      this.scrollTop = scrollTop;
    },
    "table.loading"(loading) {
      if (!loading) {
        this.diseaseList = [];
        const dataList = this.diseaseList;
        let date = null as any;
        this.tableDataList.forEach((data) => {
          const dataDate = core.date.instance(data.createdAt).format("YYYY-MM-DD");
          if (date != dataDate) {
            date = dataDate;
            dataList.push({
              subheader: true,
              date: date,
            });
          }
          //console.log("data : ", data);
          dataList.push(data);
        });
      }
    },
    selectedCropItem(cropItem) {
      //console.log("cropItem : ", cropItem);
      const items = this.getStorageItems();
      items.cropCodeItem = cropItem;
      this.saveStorageItems();
      this.clearTable();
      this.searchColumns.cropCode = cropItem.code;
      this.loadTable(this.searchColumns);
    },
    selectedCropCodeIndex(index) {
      if (index != null) {
        this.selectedCropItem = this.cropCodeList[index];
      } else {
        this.selectedCropItem = null;
      }
    },
    "modal.selectCrop.updateEvent"() {
      const event = this.getComponentUpdateEvent(this.modal.selectCrop);
      if (event != null) {
        if (event.result === UpdateEventType.CONFIRM) {
          //console.log("select crop : ", event.item);
          this.selectedCropItem = event.item;
        } else {
          console.log("unknown event : ", event);
        }
      }
    },
  },
  methods: {
    async downloadAnnotation() {
      const cropItem = this.selectedCropItem;
      if (cropItem != null) {
        core.loader.show("데이터 요청중...");
        try {
          const downloadUri = (await DiseaseService.getAnnotationFile(cropItem.code)) as string;
          //console.log("downloadUri : ", downloadUri);
          core.loader.show("다운로드중...");
          await core.http.downloadFile(downloadUri);
        } catch (e) {
          console.log(e);
        }
        core.loader.hide();
      }
    },
    select(item) {
      //console.log("select : ", item);
      this.$router.push({
        path: "/disease-manager/annotation",
        query: {
          id: item.id,
        },
      });
    },
    getDiseaseName(item) {
      if (item.analysisResultList != null) {
        if (item.analysisResultList.length > 0) {
          return item.analysisResultList[0].name;
        }
      } else if (item.annotations != null) {
        if (item.annotations.length > 0) {
          return item.annotations[0].name;
        }
      }
      // console.log("item : ", item);
      return `${item.sidoName} ${item.sggName} (판독필요)`;
      // return "판독필요";
    },
    showSelectCropModal() {
      //console.log("select crop modal");
      const modal = this.modal.selectCrop;
      modal.params.itemList = this.cropCodeList;

      modal.visible = true;
    },
  },
});

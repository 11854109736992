import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import { AppState, DiseaseAnalysis, RootState } from "@/types";
import { RouterBeforeTask } from "@/plugins/vue-page-stack/router-before-task";
import Constant from "@/store/constant";
import store from "@/store";

const state: AppState = {
  name: Constant.appName,
  size: { width: 0, height: 0 },
  routerBeforeTask: new RouterBeforeTask(),
  modal: {
    alert: {
      visible: false,
      title: "알림",
      body: "내용",
      showCancelButton: false,
      cancelButtonText: "취소",
      confirmButtonText: "확인",
      promise: {
        resolve: null,
        reject: null,
      },
      allowBackCloseEvent: true,
    },
  },
  printClass: null,
  isApp: false,
  isMobile: false,
  analysis: {
    ready: false,
    index: 0,
    items: {} as any,
  },
};

export const getters: GetterTree<AppState, RootState> = {};

export const actions: ActionTree<AppState, RootState> = {
  addPrint({ commit }, deviceType: string) {
    if (state.printClass == null) {
      document.documentElement.classList.add("print");
      document.documentElement.classList.add(deviceType);
      state.printClass = deviceType;
    }
  },
  clearPrint({ commit }) {
    if (state.printClass) {
      document.documentElement.classList.remove("print");
      document.documentElement.classList.remove("mobile");
      document.documentElement.classList.remove("pc");
      state.printClass = null;
    }
  },
  updateAnalysis({ commit }, params) {
    let addParam = false;
    const cropCode = params.cropCode;

    if (!state.analysis.items[cropCode]) {
      state.analysis.items[cropCode] = [];
    }
    const list = state.analysis.items[cropCode] as DiseaseAnalysis[];

    list.forEach((analysis) => {
      if (params.index != null) {
        if (params.index === analysis.index) {
          // 데이터 변경
          addParam = true;
          analysis.item = params.item;
          analysis.message = params.message;
          analysis.status = params.status;
          return;
        }
      }
    });

    if (!addParam) {
      list.unshift(params);
    }

    const maxLength = 10;
    if (list.length > maxLength) {
      list.splice(maxLength - 1, 1);
    }

    //console.log("analysis : ", analysis);
    store.dispatch("storage/setStorageItem", { type: "analysis", items: state.analysis });
  },
};

const mutations: MutationTree<AppState> = {};

export const app: Module<AppState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};








import mixins from "vue-typed-mixins";
import MixinsModal from "@/mixins/modal";
import SearchAddressComponent from "@/components/address/SearchAddressComponent.vue";
import UpdateEvent from "@/models";
import { UpdateEventType } from "@/types";

export default mixins(MixinsModal).extend({
  name: "SearchAddressModal",
  components: { SearchAddressComponent },
  data: () => ({
    ready: false,
    searchAddress: {
      params: {},
      updateEvent: null as UpdateEvent | null,
    },
  }),
  mounted() {
    this.$nextTick(() => {
      this.searchAddress.params = this.properties.params;
      this.ready = true;
    });
  },
  watch: {
    "searchAddress.updateEvent"(event) {
      if (event?.result === UpdateEventType.CONFIRM) {
        //const location = event.item;
        //console.log("location : ", location);
        this.close(event.result, event.item);
      }
    },
  },
});

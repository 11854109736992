

































































































































































import mixins from "vue-typed-mixins";
import AppBarProps from "@/components/core/AppBarProps.vue";
import core from "@/core";
import store from "@/store";

export default mixins(AppBarProps).extend({
  name: "AppBar",
  data: () => ({
    showMenu: false,
    companyId: 0,
    menuList: [],
    app: store.state.app,
    auth: store.state.auth,
    menuItems: {
      selected: null as any,
      list: [] as any,
    },
    isManagerRole: false,
  }),
  computed: {
    title() {
      return this.$store.getters["topToolbar/title"];
    },
    dark() {
      return this.$store.getters["topToolbar/dark"];
    },
    clazz() {
      return this.$store.getters["topToolbar/clazz"];
    },
    elevation() {
      return this.$store.getters["topToolbar/elevation"];
    },
    color() {
      return this.$store.getters["topToolbar/color"];
    },
  },
  created() {},
  mounted() {
    this.$nextTick(async () => {
      // this.prop.closeNavigation = false;
      // if (this.app.isMobile) {
      //   this.showMenu = false;
      // }
      this.changedUserInfo();
    });
  },
  watch: {
    "prop.closeNavigation"(closeNavigation) {
      // if (closeNavigation && this.app.isMobile) {
      //   this.showMenu = false;
      // }
      // this.prop.closeNavigation = false;
    },
    "auth.user"() {
      this.changedUserInfo();
    },
  },
  methods: {
    changedUserInfo() {
      const user = this.auth.user;
      if (user == null) {
        this.isManagerRole = false;
      } else {
        this.isManagerRole = user.roles.indexOf("ADMIN") > -1 || user.roles.indexOf("MANAGER") > -1;
      }
    },
    toggleMenu() {
      if (this.showMenu == null) this.showMenu = true;
      this.showMenu = !this.showMenu;
    },
    goBack() {
      this.$router.back();
    },
    async logout() {
      const result = await core.alert.show({
        title: "확인",
        body: "현재 인증된 정보가 해제됩니다.<br>계속 진행하시겠습니까?",
        showCancelButton: true,
      });
      if (result === "confirm") {
        await this.$router.push("/logout");
      }
    },
  },
});






import mixins from "vue-typed-mixins";
import MixinsComponent from "@/mixins/component";
import core from "@/core";
import CoreKakaoMap from "@/core/core-kakao-map";
import Constant from "@/store/constant";
import store from "@/store";

declare let kakao: any;

export interface Location {
  address1: string;
  address2: string | null | undefined;
  latitude: number;
  longitude: number;
}

export default mixins(MixinsComponent).extend({
  name: "MapLocationComponent",
  props: {
    location: {
      type: Object,
      default: () => {
        return {
          location: {
            address1: "",
            address2: "",
            latitude: 0,
            longitude: 0,
            level: 2,
          },
        };
      },
    },
  },
  data: () => ({
    app: store.state.app,
    map: {
      default: {
        address: "선택된 위치가 없습니다",
        latitude: 37.5653888,
        longitude: 126.9783866,
        level: 6,
      },
      findLocation: false,
    },
    kakaoMap: null as CoreKakaoMap | null,
    marker: null as any,
    updateViewType: null as any,
  }),
  mounted() {
    this.$nextTick(async () => {
      const location = this.location;
      if (location != null) {
        if (location.level == null || location.level < 1) location.level = 2;
        this.initMap(location);
      } else {
        const defaultLocation = this.map.default;
        const location = {
          address1: defaultLocation.address,
          latitude: defaultLocation.latitude,
          longitude: defaultLocation.longitude,
          level: defaultLocation.level,
        };
        this.initMap(location);
      }
    });
  },
  watch: {
    location(location) {
      if (this.kakaoMap != null && this.marker != null) {
        this.kakaoMap.setCenter(location.latitude, location.longitude);
        this.marker.setMap(null);
        this.marker = this.kakaoMap.addMarker(location.latitude, location.longitude);
      }
    },
  },
  methods: {
    initMap(params) {
      //console.log("init map");
      const mapData = this.map;
      const mapElement = this.$refs.map;
      // const mapChangeEvent = {
      //   preStatus: "",
      //   preDate: new Date(),
      // };

      const locationItem = {
        changeTime: null as Date | null,
        interval: null as any,
        latitude: null as number | null | undefined,
        longitude: null as number | null | undefined,
      };

      const vm = this;
      const kakaoMap = (this.kakaoMap = new CoreKakaoMap(
        {
          map: {
            element: mapElement,
            option: {
              center: new kakao.maps.LatLng(params.latitude, params.longitude),
              level: params.level,
            },
          },
          callback: {},
        },
        core,
        Constant.kakao.restApiKey
      ));
      this.marker = kakaoMap.addMarker(params.latitude, params.longitude);
      kakaoMap.map.setDraggable(false);
      kakaoMap.map.setZoomable(false);
    },
  },
});

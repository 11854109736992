import core from "@/core";

class CodeService {
  cropCodeList = null;

  getCropCodeList() {
    return new Promise((resolve, reject) => {
      if (this.cropCodeList == null) {
        core.http
          .getJson(`/api/code/crop`, { ignoreAlertModal: true })
          .then((data) => {
            if (data.cropCodeList) {
              this.cropCodeList = data.cropCodeList;
              resolve(data.cropCodeList);
            } else {
              reject({
                message: "not found item",
              });
            }
          })
          .catch((reason) => {
            //console.log('error: ', reason);
            reject(reason);
          });
      } else {
        resolve(this.cropCodeList);
      }
    });
  }

  async findCropCodeByEnName(cropName) {
    const cropCodeList = (await this.getCropCodeList()) as any;
    const length = cropCodeList.length;
    for (let i = 0; i < length; i++) {
      const cropCode = cropCodeList[i];
      if (cropCode.enName === cropName) {
        return cropCode.code;
      }
    }
    return null;
  }

  async findCropEnNameByCode(_cropCode) {
    const cropCodeList = (await this.getCropCodeList()) as any;
    const length = cropCodeList.length;
    for (let i = 0; i < length; i++) {
      const cropCode = cropCodeList[i];
      if (Number(cropCode.code) === Number(_cropCode)) {
        //console.log("find cropCode : ", cropCode);
        return cropCode.enName;
      }
    }
    return null;
  }
}

export default new CodeService();














import mixins from "vue-typed-mixins";
import MixinsPageForm from "@/mixins/page-form";
import UpdateEvent from "@/models";
import DiseaseAreaCountMapComponent from "@/components/disease/DiseaseAreaCountMapComponent.vue";
import { UpdateEventType } from "@/types";
import AnalysisImageModal from "@/modals/disease/AnalysisImageModal.vue";

export default mixins(MixinsPageForm).extend({
  name: "DiseaseArea",
  components: { AnalysisImageModal, DiseaseAreaCountMapComponent },
  data: () => ({
    modal: {
      analysisImage: {
        visible: false,
        params: {
          title: "",
          searchColumns: {
            cropCode: null as any,
            sidoCode: null as any,
            sggCode: null as any,
            status: "DISEASE",
          },
        },
        updateEvent: null as UpdateEvent | null,
      },
    },
    component: {
      area: {
        viewMode: false,
        visibleMyLocationButton: true,
        cropCode: null as any,
        updateEvent: null as UpdateEvent | null,
        markerClick: null as any,
      },
    },
  }),
  mounted() {
    (this as any).$eventBus.$on("markerClick", this.markerClick);

    this.$nextTick(async () => {
      const query = this.$route.query;
      if (this.isBlank(query.cropCode)) {
        await this.notFound();
        return;
      }
      this.component.area.cropCode = query.cropCode;
      //console.log("area");
    });
  },
  beforeDestroy() {
    (this as any).$eventBus.$off("markerClick", this.markerClick);
  },
  watch: {
    "component.area.updateEvent"() {
      const event = this.getComponentUpdateEvent(this.component.area);
      if (event != null) {
        //console.log("event : ", event);
        if (event.result === UpdateEventType.UPDATE) {
          const item = event.item;
          // console.log("item : ", item);
          this.showAnalysisImageModal(item);
        } else {
          console.log("unknown event : ", event);
        }
      }
    },
  },
  methods: {
    markerClick(item) {
      // console.log("call markerClick : ", item.type, ", id : ", item.id);
      this.component.area.markerClick = item;
    },
    showAnalysisImageModal(item) {
      //console.log("item : ", item);
      const modal = this.modal.analysisImage;
      modal.params.searchColumns.cropCode = this.component.area.cropCode;
      modal.params.searchColumns.sidoCode = item.sidoCode;
      modal.params.searchColumns.sggCode = item.sggCode;
      modal.params.title = item.sidoName + " " + item.sggName;
      modal.visible = true;
    },
  },
});

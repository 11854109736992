var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-main',[_c('v-form',{staticClass:"pa-5",on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('ValidationObserver',{ref:"observer"},[_c('ValidationProvider',{attrs:{"name":"user_username","rules":"required|min:1|max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"아이디","placeholder":"아이디를 입력하세요","name":"username","type":"text","autocomplete":"off","error-messages":errors,"disabled":_vm.processing,"readonly":"","outlined":"","dense":""},model:{value:(_vm.view.username),callback:function ($$v) {_vm.$set(_vm.view, "username", $$v)},expression:"view.username"}})]}}])}),_c('ValidationProvider',{attrs:{"name":"user_email","rules":"required|email|min:4|max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"이메일","placeholder":"이메일 정보를 입력하세요","name":"email","type":"email","counter":100,"autocomplete":"off","error-messages":errors,"disabled":_vm.processing,"outlined":"","dense":""},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})]}}])}),_c('ValidationProvider',{attrs:{"name":"user_name","rules":"required|min:1|max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"이름","placeholder":"이름을 입력하세요","name":"name","type":"name","counter":100,"autocomplete":"off","error-messages":errors,"disabled":_vm.processing,"outlined":"","dense":""},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})]}}])}),_c('ValidationProvider',{attrs:{"name":"user_phone","rules":"required|phone|min:12|max:13"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"휴대전화","placeholder":"휴대전화 번호를 입력하세요","name":"phone","type":"tel","counter":13,"autocomplete":"off","error-messages":errors,"disabled":_vm.processing,"outlined":"","dense":""},model:{value:(_vm.form.phone),callback:function ($$v) {_vm.$set(_vm.form, "phone", $$v)},expression:"form.phone"}})]}}])}),_c('div',{staticClass:"d-flex mt-10"},[_c('v-spacer'),_c('v-btn',{staticClass:"mr-2",attrs:{"outlined":""},on:{"click":function($event){return _vm.$router.back()}}},[_vm._v(" 취소")]),_c('v-btn',{attrs:{"type":"submit","color":"primary","outlined":""}},[_vm._v(" 저장")])],1)],1),(_vm.modal.selectCamera.visible)?_c('select-camera-modal',{attrs:{"properties":_vm.modal.selectCamera}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
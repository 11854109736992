import CoreHttp from "@/core/core-http";
import CoreUtils from "@/core/core-utils";
import CoreDate from "@/core/core-date";
import CoreLoader from "@/core/core-loader";
import CoreAlert from "@/core/core-alert";
import CoreMobile from "@/core/core-mobile";
import CoreLocation from "@/core/core-location";

class Core {
  http: CoreHttp;
  utils: CoreUtils;
  date: CoreDate;
  loader: CoreLoader;
  location: CoreLocation;
  alert: CoreAlert;
  mobile: CoreMobile;

  init(store, router) {
    this.loader = new CoreLoader();
    this.mobile = new CoreMobile();
    this.alert = new CoreAlert(store.state.app.modal.alert, this.loader);

    this.http = new CoreHttp(
      [
        {
          key: "x-user-role",
          value: "manager",
        },
      ],
      store,
      router,
      this.alert,
      this.mobile
    );
    this.utils = new CoreUtils();
    this.location = new CoreLocation(this.alert);
    this.date = new CoreDate("ko");
  }
}

export default new Core();

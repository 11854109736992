


































import mixins from "vue-typed-mixins";
import MixinsPageForm from "@/mixins/page-form";
import CodeService from "@/services/code/code.service";

export default mixins(MixinsPageForm).extend({
  name: "DiseaseCameraHelp",
  components: {},
  data: () => ({
    cropCode: "",
    items: [] as any,
    height: 400,
  }),
  mounted() {
    this.$nextTick(async () => {
      const query = this.$route.query;
      if (this.isBlank(query.cropCode)) {
        await this.notFound();
        return;
      }
      this.height = this.app.size.height - 200;

      this.cropCode = query.cropCode as string;
      const cropName = (await CodeService.findCropEnNameByCode(this.cropCode)) as string;
      //console.log("cropName : ", cropName);
      if (cropName === "tomato") {
        this.items.push({
          src: "/img/disease/help/tomato/1.jpg",
        });
        this.items.push({
          src: "/img/disease/help/tomato/2.jpg",
        });
      } else if (cropName === "strawberry") {
        this.items.push({
          src: "/img/disease/help/strawberry/1.jpg",
        });
        this.items.push({
          src: "/img/disease/help/strawberry/2.jpg",
        });
      } else {
        console.log("unknown crop name : ", cropName);
      }
    });
  },
  watch: {
    "app.size"(size) {
      this.height = this.app.size.height - 200;
    },
  },
  methods: {},
});

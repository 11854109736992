import korea from "vee-validate/dist/locale/ko.json";

class Constant {
  appName = "병해영상진단";
  validate = {
    language: {
      korea: korea,
    },
  };
  kakao = {
    restApiKey: "4d2469516db02caafb9655b3dd1f6e88",
  };
}

export default new Constant();




















import mixins from "vue-typed-mixins";
import MixinsModal from "@/mixins/modal";

export default mixins(MixinsModal).extend({
  name: "AlertModal",
  props: {
    properties: {
      type: Object,
      default: () => {
        return {
          visible: false,
          showCancelButton: false,
          title: "제목",
          body: "내용",
          cancelButtonText: "취소",
          confirmButtonText: "확인",
          promise: {
            resolve: null,
            reject: null,
          },
          allowBackCloseEvent: true,
        };
      },
    },
  },
  data: () => ({}),
  methods: {
    confirm() {
      //console.log('confirm');
      this.properties.visible = false;
      if (this.properties.promise != null) {
        this.properties.promise.resolve("confirm");
      }
    },

    cancel() {
      //console.log('cancel');
      this.properties.visible = false;
      if (this.properties.promise != null) {
        this.properties.promise.resolve("cancel");
      }
    },
  },
});

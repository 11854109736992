











































































































import mixins from "vue-typed-mixins";
import MixinsPageForm from "@/mixins/page-form";
import core from "@/core";
import EXIF from "exif-js";
import UpdateEvent from "@/models";
import SearchAddressModal from "@/modals/core/SearchAddressModal.vue";
import { DiseaseAnalysis, UpdateEventType } from "@/types";
import CoreKakaoMap from "@/core/core-kakao-map";
import Constant from "@/store/constant";
import DiseaseService from "@/services/disease/disease.service";

export default mixins(MixinsPageForm).extend({
  name: "DiseaseCamera",
  components: {
    SearchAddressModal,
  },
  data: () => ({
    storageItems: {
      type: "analysis",
    },
    accept: "image/*",
    cropCode: "",
    image: {
      item: null,
      src: null,
    } as any,
    modal: {
      searchAddress: {
        visible: false,
        params: {
          type: "add",
          item: null,
          loadMyLocation: false,
        },
        updateEvent: null as UpdateEvent | null,
      },
      helpCamera: {
        visible: false,
        updateEvent: null as UpdateEvent | null,
      },
    },
  }),
  mounted() {
    this.$nextTick(async () => {
      const query = this.$route.query;
      if (this.isBlank(query.cropCode)) {
        await this.notFound();
        return;
      }
      this.cropCode = query.cropCode as string;
    });
  },
  watch: {
    "modal.searchAddress.updateEvent"() {
      const event = this.getComponentUpdateEvent(this.modal.searchAddress);
      if (event != null) {
        if (event.result === UpdateEventType.CONFIRM) {
          //console.log("event : ", event);
          const item = event.item;
          const image = this.image;
          image.item.latitude = item.latitude;
          image.item.longitude = item.longitude;
          image.item.address1 = item.address1;
          image.item.address2 = item.address2;
          if (image.item.address2 == null) {
            image.item.address2 = "";
          }
          this.submitAnalysis();
        } else {
          console.log("unknown event : ", event);
        }
      }
    },
  },
  methods: {
    moveHelpPage() {
      this.$router.push({ path: "/disease/camera/help", query: { cropCode: this.cropCode } });
    },
    showCamera() {
      (this.$refs.cameraFile as any).click();
    },
    showImage() {
      (this.$refs.imageFile as any).click();
    },
    onChangeImageFile(e) {
      this.inputImageFile(e.target.files);
    },
    async inputImageFile(files) {
      if (files.length) {
        let file = files[0];
        if (!/^image\//.test(file.type)) {
          await core.alert.show({
            title: "알림",
            body: "이미지 파일만 등록이 가능합니다",
          });
          return false;
        }
        this.image.name = file.name;
        await this.changedImage(file);
        await this.submitAnalysis();
      }
    },

    async preview(file) {
      const image = this.image;
      core.loader.show("이미지 크기 변환중...");
      const item = (image.item = await core.utils.image.getImageBlob(file, 1980)) as any;
      image.src = item.dataUri;
      core.loader.hide();
      return item;
    },
    changedImage(file) {
      return new Promise(async (resolve: any, reject) => {
        await this.preview(file);

        function convertGpsDmsToDegree(degrees, minutes, seconds, direction) {
          let dd = degrees + minutes / 60 + seconds / (60 * 60);

          if (direction === "S" || direction === "W") {
            dd = dd * -1;
          } // Don't do anything for N or E
          return dd;
        }

        const item = this.image.item;
        EXIF.getData(file, () => {
          const allMetaData = EXIF.getAllTags(file);
          //console.log("allMetaData : ", allMetaData);
          const gpaLatitude = allMetaData.GPSLatitude;
          const gpaLongitude = allMetaData.GPSLongitude;
          if (gpaLatitude != null && gpaLongitude != null) {
            const latDegree = gpaLatitude[0].numerator / gpaLatitude[0].denominator;
            const latMinute = gpaLatitude[1].numerator / gpaLatitude[1].denominator;
            const latSecond = gpaLatitude[2].numerator / gpaLatitude[2].denominator;
            const latDirection = allMetaData.GPSLatitudeRef;
            item.latitude = Number(
              convertGpsDmsToDegree(latDegree, latMinute, latSecond, latDirection).toFixed(7)
            );

            const lngDegree = gpaLongitude[0].numerator / gpaLongitude[0].denominator;
            const lngMinute = gpaLongitude[1].numerator / gpaLongitude[1].denominator;
            const lngSecond = gpaLongitude[2].numerator / gpaLongitude[2].denominator;
            const lngDirection = allMetaData.GPSLongitudeRef;
            item.longitude = Number(
              convertGpsDmsToDegree(lngDegree, lngMinute, lngSecond, lngDirection).toFixed(7)
            );
          }
          resolve();
        });
      });
    },
    async submitAnalysis() {
      const image = this.image;
      //console.log("image : ", image);

      if (image.item.latitude == null || image.item.longitude == null) {
        //console.log("위치 지정 필요");
        const result = await core.alert.show({
          title: "알림",
          body: "사진 촬영 위치를 선택해주세요",
          showCancelButton: true,
          confirmButtonText: "계속",
        });
        if (result === "confirm") {
          this.modal.searchAddress.params.loadMyLocation = true;
          this.modal.searchAddress.visible = true;
        }
        return;
      }

      core.loader.show("병해 영상 분석 중...");
      try {
        const formData = new FormData();
        formData.append("fileObj", image.item.blob, image.item.filename);
        formData.append("latitude", image.item.latitude);
        formData.append("longitude", image.item.longitude);
        if (image.item.address1 == null) {
          // 주소 가져오기
          const kakaoMap = new CoreKakaoMap(null, core, Constant.kakao.restApiKey);
          const result = (await kakaoMap.findAddressByLatAndLng(
            image.item.latitude,
            image.item.longitude
          )) as any;
          image.item.address1 = result.address;
          image.item.address2 = result.buildingName;
          if (image.item.address2 == null) image.item.address2 = "";
          console.log("result : ", result);
        }
        formData.append("address1", image.item.address1);
        formData.append("address2", image.item.address2);
        formData.append("cropCode", this.cropCode);
        //   if (this.engine.visible) {
        //     const item = this.engine.versionList[this.engine.model];
        //     formData.append("engine", item.id);
        //   }
        //console.log("formData : ", formData);

        const index = this.app.analysis.index++;

        const diseaseAnalysis = {
          index: index,
          cropCode: Number(this.cropCode),
          status: "LOADING",
          item: null,
          date: core.date.instance().format("YYYY-MM-DD hh:mm:ss"),
        } as DiseaseAnalysis;

        this.$store.dispatch("app/updateAnalysis", diseaseAnalysis);

        DiseaseService.imageAnalysis(formData)
          .then((analysis) => {
            //console.log("analysis : ", analysis);
            diseaseAnalysis.status = "DONE";
            diseaseAnalysis.item = analysis;
            // const diseaseAnalysis = {
            //   index: index,
            //   cropCode: Number(this.cropCode),
            //   status: "DONE",
            //   item: analysis,
            // } as DiseaseAnalysis;
            this.$store.dispatch("app/updateAnalysis", diseaseAnalysis);
          })
          .catch((e) => {
            console.log(e);
            //this.$toast.error(e.message);
            diseaseAnalysis.status = "ERROR";
            diseaseAnalysis.message = e.message;
            // const diseaseAnalysis = {
            //   index: index,
            //   cropCode: Number(this.cropCode),
            //   status: "ERROR",
            //   message: e.message,
            //
            // } as DiseaseAnalysis;
            this.$store.dispatch("app/updateAnalysis", diseaseAnalysis);
          });
      } catch (e) {
        console.log(e);
        core.alert.show({
          title: "오류",
          body: e.message,
        });
      } finally {
        core.loader.hide();
        this.goBack();
      }
    },
  },
});

/* eslint-disable no-console */

import { register } from "register-service-worker";

if (process.env.NODE_ENV === "production") {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      console.log(
        "App is being served from cache by a service worker.\n" +
          "For more details, visit https://goo.gl/AFskqB"
      );
    },
    registered() {
      console.log("Service worker has been registered.");
    },
    cached() {
      console.log("Content has been cached for offline use.");
    },
    updatefound() {
      console.log("New content is downloading.");
    },
    updated() {
      console.log("New content is available; please refresh.");
      try {
        (window as any)
          .showModal({
            title: "알림",
            body: "업데이트가 발견되었습니다.<br>적용하시겠습니까?",
            showCancelButton: true,
            cancelButtonText: "아니오",
            confirmButtonText: "예",
          })
          .then((result) => {
            if (result === "confirm") {
              window.location.reload();
            }
          });
      } catch (e) {
        setTimeout(function () {
          window.location.reload();
        }, 1000);
      }
    },
    offline() {
      console.log(
        "No internet connection found. App is running in offline mode."
      );
    },
    error(error) {
      console.error("Error during service worker registration:", error);
    },
  });
}
























































































































































































































































































































































































































































































































































































































































import mixins from "vue-typed-mixins";
import MixinsPageForm from "@/mixins/page-form";
import UpdateEvent from "@/models";
import DiseaseService from "@/services/disease/disease.service";
import DepartmentService from "@/services/department/department.service";
import CodeService from "@/services/code/code.service";
import SearchAddressModal from "@/modals/core/SearchAddressModal.vue";
import VueUploadMultipleImage from "@/components/core/VueUploadMultipleImage.vue";
import core from "@/core";
import { cloneDeep } from "lodash";
import { UpdateEventType } from "@/types";

export default mixins(MixinsPageForm).extend({
  name: "DiseaseAnalysisRequest",
  components: { VueUploadMultipleImage, SearchAddressModal },
  data: () => ({
    processing: false,
    form: {
      cropCode: "",
      latitude: null,
      longitude: null,
      address1: null,
      address2: null,
      farmerName: null,
      authorEmail: null,
      kindCode: null,
      date: null,
      temp: null,
      humidity: null,
      diseaseName: null,
      diseaseStep: null,
      cropPosition: null,
      housePosition: null,
      departmentId: null,
    } as any,
    modal: {
      searchAddress: {
        visible: false,
        params: {
          location: null as any,
          loadMyLocation: true,
        },
        updateEvent: null as UpdateEvent | null,
      },
    },
    view: {
      address: "",
    },

    datePicker: {
      visible: false,
      date: "",
    },
    currentIndexImage: null as any,
    images: [] as any,
    cropCodeList: [] as any,
    kindCodeList: [
      {
        text: "단동",
        value: "S",
      },
      {
        text: "연동",
        value: "M",
      },
    ],
    diseaseStepList: [
      {
        text: "초기",
        value: "1",
      },
      {
        text: "중기",
        value: "2",
      },
      {
        text: "말기",
        value: "3",
      },
    ],
    uploadMultipleImage: {
      imageList: [] as any,
      maxSize: 20,
      uploadImageList: [] as any,
    },
    requestAnalysis: null as any,
    ready: false,
    departmentList: [] as any,
  }),
  mounted() {
    this.$nextTick(async () => {
      // 부서 정보 조회
      this.departmentList = await DepartmentService.getList();
      this.cropCodeList = await CodeService.getCropCodeList();

      const cropCode = this.$route.query.cropCode;
      if (this.isNotBlank(cropCode)) {
        this.form.cropCode = cropCode;
      } else {
        await this.notFound();
        return;
      }
      this.ready = true;
    });
  },
  watch: {
    "form.cropCode"(cropCode) {
      if (this.ready) {
        this.form.cropPosition = null;
      }
    },
    "modal.searchAddress.updateEvent"(event) {
      if (event != null) {
        //console.log("event : ", event);
        if (event.result === UpdateEventType.CONFIRM) {
          const item = event.item;
          if (item.address2 != null) {
            this.view.address = `${item.address1} ${item.address2}`;
          } else {
            this.view.address = `${item.address1}`;
          }
          this.form.address1 = item.address1;
          this.form.address2 = item.address2;
          if (this.form.address2 == null) this.form.address2 = "";
          this.form.latitude = item.latitude;
          this.form.longitude = item.longitude;
        }
      }
    },
  },
  methods: {
    async submit() {
      if (await this.validate()) {
        const uploadImageList = this.uploadMultipleImage.uploadImageList;
        const imageTotalSize = uploadImageList.length;
        if (uploadImageList.length == 0) {
          await core.alert.show({ title: "확인", body: "이미지 파일은 필수입니다" });
          return;
        }
        this.processing = true;

        const imageFileIdList = [] as any;
        let count = 0;
        for (const image of uploadImageList) {
          try {
            if (image.id != null) {
              imageFileIdList.push(image.id);
            } else {
              const formData = new FormData();
              formData.append("imageFile", image.blob, image.name);
              core.loader.show(`이미지 업로드 ${++count}/${imageTotalSize}`);
              const fileDetailId = await DiseaseService.createRequestDiseaseAnalysisImage(formData);
              imageFileIdList.push(fileDetailId);
            }
          } catch (e) {
            console.log(e);
            await core.alert.show({
              title: "알림",
              body: "이미지 업로드 실패!",
            });
            this.processing = false;
            return;
          }
        }

        // 병해 정보 저장
        const params = cloneDeep(this.form);
        params.imageFileIdList = imageFileIdList;
        console.log("params : ", params);
        try {
          const requestAnalysis = await DiseaseService.createRequestDiseaseAnalysis(params);
          console.log("create, requestAnalysis : ", requestAnalysis);
          await core.alert.show({
            title: "알림",
            body: "전문가 확인 후 결과는 이메일로 전송됩니다",
          });

          this.$router.back();
        } catch (e) {
          console.log(e);
        }
        core.loader.hide();
      }
    },
    changeHighlight(currentIndex) {
      this.currentIndexImage = currentIndex;
      let arr = this.images;
      this.images = [];
      arr.map((item, index) => {
        if (currentIndex === index) {
          item.highlight = 1;
        } else {
          item.highlight = 0;
        }
        return item;
      });
      this.images = arr;
    },
    searchAddress() {
      const modal = this.modal.searchAddress;
      //console.log("form :", this.form);
      if (this.form.latitude != null) {
        modal.params.location = {
          latitude: this.form.latitude,
          longitude: this.form.longitude,
          address1: this.form.address1,
          address2: this.form.address2,
          level: 2,
        };
        //console.log("modal params : ", modal.params);
      }
      modal.visible = true;
    },

    uploadImageSuccess(index, fileList) {
      this.uploadMultipleImage.uploadImageList = fileList;
    },
    changedImageFileList(index, fileList) {
      this.uploadMultipleImage.uploadImageList = fileList;
    },
    beforeRemove(index, done, fileList) {
      console.log("beforeRemove");
      done();
      this.uploadMultipleImage.uploadImageList = fileList;
    },
    editImage(index, fileList) {
      console.log("edit image", index, fileList);
      this.uploadMultipleImage.uploadImageList = fileList;
      delete fileList[index].id;
    },
    limitExceeded() {
      console.log("limitExceeded");
      core.alert.show({
        title: "알림",
        body: `최대 업로드 수(${this.uploadMultipleImage.maxSize})를 초과하였습니다.`,
      });
    },

    isSelectedCrop(crop) {
      let cropCode = null as any;
      this.cropCodeList.some((_crop) => {
        if (_crop.enName === crop) {
          cropCode = _crop;
          return true;
        }
      });

      if (cropCode != null) {
        return this.form.cropCode === cropCode.code;
      }
      return false;
    },
    changeCropPosition(position) {
      this.form.cropPosition = String(position);
      //console.log("change crop position : ", position);
    },
    getCropPositionStyle(position) {
      position = String(position);
      //console.log("get crop position : ", position);
      if (this.form.cropPosition === position) {
        return "border: 2px solid #38ff00; background: rgba(0, 0, 0, 0.8)";
      }
      return "";
    },
    changeHousePosition(position) {
      this.form.housePosition = String(position);
    },
    getHousePositionStyle(position) {
      position = String(position);
      if (this.form.housePosition === position) {
        return "border: 2px solid #38ff00; background: rgba(0, 0, 0, 0.8)";
      }
      return "";
    },
  },
});

















































import mixins from "vue-typed-mixins";
import MixinsModal from "@/mixins/modal";
import UpdateEvent from "@/models";
import { ComponentProperties } from "@/mixins/component";
import DiseaseService from "@/services/disease/disease.service";
import core from "@/core";
import MixinsTable from "@/mixins/single/table";
import CodeService from "@/services/code/code.service";

export default mixins(MixinsModal, MixinsTable).extend({
  name: "AnalysisImageModal",
  props: {
    properties: {
      type: Object as () => ComponentProperties,
      default: () => {
        return {
          visible: false,
          params: {
            title: "",
            searchColumns: {
              cropCode: null as any,
              sidoCode: null as any,
              sggCode: null as any,
              status: "DISEASE",
            },
          },
          // 업데이트 이벤트 처리용 변수
          updateEvent: null as UpdateEvent | null,
        } as ComponentProperties;
      },
    },
  },
  data: () => ({
    maxHeight: 300,
    table: {
      body: null as any,
      defaultColumns: [],
    },
    scrollTop: 0,
    scroll: {
      enable: true,
      first: true,
      eventFunc: null as any,
      scrollTop: 0,
    },
    dataList: [] as any,
    ready: false,
    selectedItem: null as any,
  }),
  mounted() {
    this.$nextTick(async () => {
      const height = this.app.size.height - 56;
      if (height > 400) {
        this.maxHeight = 400;
      } else {
        this.maxHeight = height;
      }

      const $el = this.$refs.body as any;
      this.table.body = $el;

      // 테이블 초기화
      this.initTable({
        service: DiseaseService,
        serviceFunctionKey: "getTable",
        itemHeight: 56,
        requestLength: 10,
      });

      const scroll = this.scroll;
      scroll.eventFunc = function () {
        scroll.scrollTop = $el.scrollTop;
        // scroll.scrollHeight = $el.scrollHeight;
        // scroll.clientHeight = $el.clientHeight;
        //console.log("scrollTop : ", $el.scrollTop);
      };

      $el.addEventListener("scroll", scroll.eventFunc);

      // 테이블 정보 가져오기
      this.loadTable(this.properties.params.searchColumns);

      // this.cropCode = this.properties.params.searchColumns;
      //console.log("itemList : ", this.properties.params.itemList);
      // this.table.event.reloadFlag = true;
      this.ready = true;
    });
  },
  beforeRouteLeave(to, from, next) {
    const el = this.$refs.viewer as any;
    if (el != null) {
      el.$viewer.destroy();
    }
    next();
  },
  destroyed() {
    //console.log("destroyed");
    const $el = this.$refs.body as any;
    if ($el != null) {
      $el.removeEventListener("scroll", this.scroll.eventFunc);
    }
  },
  watch: {
    "scroll.scrollTop"(scrollTop) {
      //console.log("change scrollTop : ", scrollTop);
      this.scrollTop = scrollTop;
    },
    "table.loading"(loading) {
      if (!loading) {
        this.dataList = [];
        const dataList = this.dataList;
        let date = null as any;
        this.tableDataList.forEach((data) => {
          const dataDate = core.date.instance(data.createdAt).format("YYYY-MM-DD");
          if (date != dataDate) {
            date = dataDate;
            dataList.push({
              subheader: true,
              date: date,
            });
          }
          data.analysisResult = data.analysisResultList[0];
          dataList.push(data);
        });
      }
    },
  },
  methods: {
    select(item) {
      // console.log("select item : ", item);
      const el = document.querySelector(".viewer-container  > .viewer-footer > .viewer-title");
      if (el != null) {
        el.classList.remove("active");
      }

      this.selectedItem = item;
      this.showViewerImage();
    },
    showViewerImage() {
      //console.log("showViewerImage");
      const el = this.$refs.viewerImage as any;
      if (el != null) {
        //console.log("el : ", el);
        el.click();
      } else {
        setTimeout(() => {
          this.showViewerImage();
        }, 100);
      }
    },
    getThumbImageUrl(item) {
      return item.thumbAnalysisImageUrl;
    },
    getImageUrl(item) {
      return item.analysisImageUrl;
    },
    async getImageTitle($el) {
      //console.log("el : ", $el);
      const id = parseInt($el.alt);
      let item = null as any;
      this.table.response.data.some((_item: any) => {
        if (_item.id === id) {
          item = _item;
          return true;
        }
      });
      if (item != null) {
        if (item.analysisResultList) {
          const cropName = (await CodeService.findCropEnNameByCode(item.cropCode)) as string;

          let html =
            "<div class='toggle-btn'><i class='fa fa-info' aria-hidden='true'></i> 분석정보확인</div><div class='viewer-title-content'>";
          for (let i = 0; i < item.analysisResultList.length; i++) {
            const analysisResult = item.analysisResultList[i];
            // html += `<p><a href="${this.getAnalysisInfoUrl(
            //   cropName,
            //   analysisResult
            // )}" target="_blank">${i + 1}. ${analysisResult.name}</a></p>`;

            const url = this.getAnalysisInfoUrl(cropName, analysisResult);
            html += `<p><a href="#" onclick="openWindow('${url}')">${i + 1}. ${
              analysisResult.name
            }</a></p>`;
          }
          html += "</div>";

          const $rootEl = $el.parentElement.parentElement;
          const $titleEl = $rootEl.querySelector(".viewer-title");
          $titleEl.classList.remove("active");
          setTimeout(() => {
            $titleEl.innerHTML = html;
            $rootEl.querySelector(".toggle-btn").addEventListener("click", () => {
              //console.log("click");
              if ($titleEl.classList.contains("active")) {
                $titleEl.classList.remove("active");
              } else {
                $titleEl.classList.add("active");
              }
            });
          }, 50);
          // console.log(text);
          // return text;
          return "";
        }
      } else {
        return "";
      }
    },
    getAnalysisInfoUrl(cropName, analysisResult) {
      return DiseaseService.getInfoUrl(cropName, analysisResult.name, analysisResult.similarity);
    },
  },
});



















import mixins from "vue-typed-mixins";

export default mixins().extend({
  name: "HintView",
  props: {
    properties: {
      type: Object,
      default: () => {
        return {
          visible: false,
        };
      },
    },
  },
  data: () => ({}),
  mounted() {
    this.$nextTick(() => {});
  },
});

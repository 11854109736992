





























































































































































































































































import mixins from "vue-typed-mixins";
import MixinsPageForm from "@/mixins/page-form";
import MapLocationComponent from "@/components/address/MapLocationComponent.vue";
import HintView from "@/components/core/HintView.vue";
import DiseaseAreaCountMapComponent from "@/components/disease/DiseaseAreaCountMapComponent.vue";
import UpdateEvent from "@/models";
import CodeService from "@/services/code/code.service";
import MixinsStorage from "@/mixins/single/storage";
import SelectCropModal from "@/modals/disease/SelectCropModal.vue";
import { DiseaseAnalysis, UpdateEventType } from "@/types";
import DiseaseService from "@/services/disease/disease.service";

export default mixins(MixinsPageForm, MixinsStorage).extend({
  name: "Disease",
  components: { SelectCropModal, DiseaseAreaCountMapComponent, HintView, MapLocationComponent },
  data: () => ({
    storageItems: {
      type: "disease",
    },
    modal: {
      selectCrop: {
        visible: false,
        params: {
          itemList: null as any,
        },
        updateEvent: null as UpdateEvent | null,
      },
    },
    component: {
      area: {
        viewMode: true,
        cropCode: null as any,
        updateEvent: null as UpdateEvent | null,
      },
      // hintView: {
      //   visible: true,
      // },
    },
    cropCodeList: [] as any,
    selectedCropCodeIndex: null as any,
    selectedCropItem: null as any,
    diseaseList: [] as DiseaseAnalysis[],
    activated: false,
    selectedItem: null as any,
    // visible: {
    //   hintView: false,
    // },
  }),
  beforeRouteLeave(to, from, next) {
    const el = this.$refs.viewer as any;
    if (el != null) {
      el.$viewer.destroy();
    }
    next();
  },
  mounted() {
    this.$nextTick(async () => {
      const cropCodeList = (await CodeService.getCropCodeList()) as any;
      this.cropCodeList = cropCodeList;
      //console.log("cropCodeList : ", cropCodeList);
      await this.storageWait();
      const items = this.getStorageItems();
      if (items.cropCodeItem == null) {
        cropCodeList.some((cropCode, index) => {
          if (cropCode.enName == "tomato") {
            // this.selectedCropItem = cropCode;
            this.selectedCropCodeIndex = index;
            return true;
          }
        });
      } else {
        // this.selectedCropItem = items.cropCodeItem;
        const cropCodeItem = items.cropCodeItem;
        cropCodeList.some((cropCode, index) => {
          if (cropCode.code == cropCodeItem.code) {
            // this.selectedCropItem = cropCode;
            this.selectedCropCodeIndex = index;
            return true;
          }
        });
      }
      // if (items.hintView == null) {
      //   items.hintView = true;
      // }
      // this.visible.hintView = items.hintView;

      // this.initDiseaseList();
      // //console.log("items : ", items);
    });
  },
  watch: {
    selectedCropItem(cropItem) {
      //console.log("cropItem : ", cropItem);
      const items = this.getStorageItems();
      items.cropCodeItem = cropItem;
      this.component.area.cropCode = cropItem.code;
      this.saveStorageItems();
      this.initDiseaseList();
    },
    selectedCropCodeIndex(index) {
      if (index != null) {
        this.selectedCropItem = this.cropCodeList[index];
      } else {
        this.selectedCropItem = null;
      }
    },
    "modal.selectCrop.updateEvent"() {
      const event = this.getComponentUpdateEvent(this.modal.selectCrop);
      if (event != null) {
        if (event.result === UpdateEventType.CONFIRM) {
          //console.log("select crop : ", event.item);
          this.selectedCropItem = event.item;
        } else {
          console.log("unknown event : ", event);
        }
      }
    },
    // "component.hintView.visible"(visible) {
    //   if (!visible) {
    //     this.clearHintView();
    //   }
    // },
  },
  activated() {
    this.initDiseaseList();
  },
  methods: {
    moveAnalysisRequestPage() {
      this.$router.push({
        path: "/disease/analysis-request",
        query: {
          cropCode: this.selectedCropItem.code,
        },
      });
    },
    // clearHintView() {
    //   if (this.visible.hintView) {
    //     this.visible.hintView = false;
    //     const items = this.getStorageItems();
    //     items.hintView = false;
    //     this.saveStorageItems();
    //   }
    // },
    getDiseaseAnalysisTitle(item) {
      const analysis = item.item;
      if (analysis != null) {
        if (analysis.analysisResultList != null && analysis.analysisResultList.length > 0) {
          let title = item.item.analysisResultList[0].name;
          if (analysis.analysisResultList.length > 1) {
            title += ` 외 ${analysis.analysisResultList.length}건`;
          }
          return title;
        }
      }
      return "결과없음";
    },
    initDiseaseList() {
      if (this.selectedCropItem != null) {
        const cropCode = Number(this.selectedCropItem.code);
        if (!this.app.analysis.items[cropCode]) {
          this.diseaseList = [];
        } else {
          this.diseaseList = this.app.analysis.items[cropCode] as DiseaseAnalysis[];
        }
        //console.log("diseaseList : ", this.diseaseList);

        if (this.diseaseList.length > 0) {
          const diseaseAnalysis = this.diseaseList[0];
          if (diseaseAnalysis.status === "LOADING") {
            setTimeout(() => {
              document.documentElement.scrollTop = 0;
            }, 500);
          }
        }
      }
    },
    showDiseaseAnalysisImage(item) {
      const analysis = item.item;
      if (analysis != null) {
        const el = document.querySelector(".viewer-container  > .viewer-footer > .viewer-title");
        if (el != null) {
          el.classList.remove("active");
        }

        this.selectedItem = analysis;
        this.showViewerImage();
      }
    },
    showViewerImage() {
      //console.log("showViewerImage");
      const el = this.$refs.viewerImage as any;
      if (el != null) {
        //console.log("el : ", el);
        el.click();
      } else {
        setTimeout(() => {
          this.showViewerImage();
        }, 100);
      }
    },
    moveAreaPage() {
      // console.log("move area page");
      this.$router.push({
        path: "/disease/area",
        query: { cropCode: this.selectedCropItem.code },
      });
    },
    moveCameraPage() {
      //console.log("move camera page");
      // this.$router.push("/disease/camera");
      this.$router.push({
        path: "/disease/camera",
        query: { cropCode: this.selectedCropItem.code },
      });
    },
    showSelectCropModal() {
      // this.clearHintView();

      //console.log("select crop modal");
      const modal = this.modal.selectCrop;
      modal.params.itemList = this.cropCodeList;

      modal.visible = true;
    },
    getThumbImageUrl(item) {
      return item.thumbAnalysisImageUrl;
    },
    getImageUrl(item) {
      return item.analysisImageUrl;
    },
    async getImageTitle($el) {
      //console.log("el : ", $el);
      const id = parseInt($el.alt);
      let item = null as any;
      this.diseaseList.some((_item: any) => {
        const analysis = _item.item;
        if (analysis != null) {
          if (analysis.id === id) {
            item = analysis;
            return true;
          }
        }
      });
      if (item != null) {
        if (item.analysisResultList) {
          const cropName = (await CodeService.findCropEnNameByCode(item.cropCode)) as string;

          let html =
            "<div class='toggle-btn'><i class='fa fa-info' aria-hidden='true'></i> 분석정보확인</div><div class='viewer-title-content'>";
          for (let i = 0; i < item.analysisResultList.length; i++) {
            const analysisResult = item.analysisResultList[i];
            // html += `<p><a href="${this.getAnalysisInfoUrl(
            //   cropName,
            //   analysisResult
            // )}" target="_blank">${i + 1}. ${analysisResult.name}</a></p>`;

            const url = this.getAnalysisInfoUrl(cropName, analysisResult);
            html += `<p><a href="#" onclick="openWindow('${url}')">${i + 1}. ${
              analysisResult.name
            }</a></p>`;
          }
          html += "</div>";

          const $rootEl = $el.parentElement.parentElement;
          const $titleEl = $rootEl.querySelector(".viewer-title");
          $titleEl.classList.remove("active");
          setTimeout(() => {
            $titleEl.innerHTML = html;
            $rootEl.querySelector(".toggle-btn").addEventListener("click", () => {
              //console.log("click");
              if ($titleEl.classList.contains("active")) {
                $titleEl.classList.remove("active");
              } else {
                $titleEl.classList.add("active");
              }
            });
          }, 50);
          // console.log(text);
          // return text;
          return "";
        }
      } else {
        return "";
      }
    },
    getAnalysisInfoUrl(cropName, analysisResult) {
      return DiseaseService.getInfoUrl(cropName, analysisResult.name, analysisResult.similarity);
    },
  },
});

import core from "@/core";
import AbstractCoreService from "@/services/core/abstract-core.service";

class DepartmentService extends AbstractCoreService{
  constructor() {
    super("/api/department", "department");
  }
}

export default new DepartmentService();
